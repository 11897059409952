

const EffectCardBox = (props)=> {
    return (
        props.effectCardBoxData.map(function (a , i) {
            return(
                <div className="effect-card-box-item wow fadeInUp" key={i}>
                    <div className="card">
                        <dl className="card-desc">
                            <dt>{props.effectCardBoxData[i].bigEffect}</dt>
                            <dd>{props.effectCardBoxData[i].firstEffect}</dd>
                            <dd>{props.effectCardBoxData[i].secondEffect}</dd>
                        </dl>
                    </div>
                </div>
            )
        })
    )
}

export default EffectCardBox;

