import companyInfoImagePC from "../../../images/sub-visual-company-pc.jpg";
import companyInfoImageMO from "../../../images/sub-visual-company-mo.jpg";
import yearImagePC from "../../../images/sub-visual-history-pc.jpg";
import yearImageMO from "../../../images/sub-visual-history-mo.jpg";
import organizationPC from "../../../images/sub-visual-organization-pc.jpg";
import organizationMO from "../../../images/sub-visual-organization-mo.jpg";
import locationPC from "../../../images/sub-visual-location-pc.jpg";
import locationMO from "../../../images/sub-visual-location-mo.jpg";

const InfoData = [
    {
        location: "companyInfo",
        name:  "회사 개요",
        enName: "ABOUT",
        koName: "회사 개요",
        imageNamePC: companyInfoImagePC,
        imageNameMO: companyInfoImageMO
    },
    {
        location: "years",
        name:  "주요 연혁",
        enName: "HISTORY",
        koName: "주요 연혁",
        imageNamePC: yearImagePC,
        imageNameMO: yearImageMO
    },

    {
        location: "organization",
        name:  "조직도",
        enName: "ORGANIZATION",
        koName: "조직도",
        imageNamePC: organizationPC,
        imageNameMO: organizationMO
    },

    {
        location: "waytocome",
        name:  "오시는길",
        enName: "LOCATION",
        koName: "오시는길",
        imageNamePC: locationPC,
        imageNameMO: locationMO
    },

]


export const introDataFunction = (location) => {

    for (let i = 0; i <= InfoData.length; i++ ){
        if( location == InfoData[i].location ) return InfoData[i];
    }
}