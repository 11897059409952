import {Link} from "react-router-dom";

export const MainBannerSection = () => {
    return (
        <section className="main-business-area-section">
            <div className="layout1">
                <div className="main-business-area-title wow fadeInUp">
                    <img src="/images/logo2.png"/>
                    <h1 style={{fontWeight:"bold"}}>BUSINESS AREA</h1>
                </div>
                <div className="main-business-area-banners">
                    <div className="banner-col">
                        <div className="banner-item banner-item-mobility wow fadeInUp">
                            <Link className="banner-item-link" to="/businessIntro/vehiclecontrol">
                                <div className="banner-item-desc">
                                    <dl>
                                        <dt className="banner-item-desc-title1">MOBILITY</dt>
                                        <dd className="banner-item-desc-title2">차량관제솔루션</dd>
                                        <dd className="banner-item-desc-text">차량관제솔루션 차량관제솔루션 차량관제솔루션 차량관제솔루션 차량관제솔루션</dd>
                                        <dd className="banner-item-desc-button"><span className="vbtn btn-more">더 알아보기 <i
                                            className="ri-arrow-right-s-line"></i></span></dd>
                                    </dl>
                                </div>

                                <div className="banner-item-img"><img src="/images/main-business-mobility.jpg" alt=""/></div>
                            </Link>
                        </div>

                        <div className="banner-item banner-item-farm wow fadeInUp">
                            <Link className="banner-item-link" to="/businessIntro/agriculturalProductsDistribution">
                                <div className="banner-item-desc">
                                    <dl>
                                        <dt className="banner-item-desc-title1">FARM SOLUTION</dt>
                                        <dd className="banner-item-desc-title2">농산물 유통 솔루션 FARMDA</dd>
                                        <dd className="banner-item-desc-text">생산자는 오직 생산만 신경쓸 수 있게! 유통부터 배송까지 농산물 생산자의 편의성을 높인 유통 솔루션
                                            팜다
                                        </dd>
                                        <dd className="banner-item-desc-button"><span className="vbtn btn-more">더 알아보기 <i
                                            className="ri-arrow-right-s-line"></i></span></dd>
                                    </dl>
                                </div>
                                <div className="banner-item-img"><img src="/images/main-business-farm.jpg" alt=""/></div>
                            </Link>
                        </div>
                    </div>

                    <div className="banner-col">
                        <div className="banner-item banner-item-mobile wow fadeInUp">
                            <Link className="banner-item-link" to="/businessIntro/mobileplatform">
                                <div className="banner-item-desc">
                                    <dl>
                                        <dt className="banner-item-desc-title1">MOBILE</dt>
                                        <dd className="banner-item-desc-title2">모바일 플랫폼</dd>
                                        <dd className="banner-item-desc-text">모바일 플랫폼 모바일 플랫폼 모바일 플랫폼 모바일 플랫폼 모바일 플랫폼</dd>
                                        <dd className="banner-item-desc-button"><span className="vbtn btn-more">더 알아보기 <i
                                            className="ri-arrow-right-s-line"></i></span></dd>
                                    </dl>
                                </div>
                            </Link>
                        </div>

                        <div className="banner-item banner-item-si wow fadeInUp">
                            <Link className="banner-item-link" to="/businessIntro/siIntro">
                                <div className="banner-item-desc">
                                    <dl>
                                        <dt className="banner-item-desc-title1">SI</dt>
                                        <dd className="banner-item-desc-text">6년 이상의 개발자로 이루어진 개발 전문 회사 이알솔루션에서 진행한 사례 소개</dd>
                                        <dd className="banner-item-desc-button"><span className="vbtn btn-more">더 알아보기 <i
                                            className="ri-arrow-right-s-line"></i></span></dd>
                                    </dl>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}