

let  SolutionCardData = [
        {
            name: "PUSH 솔루션",
            content: "모바일기기의 푸시기능을 활용하실 수 있습니다. 기존의 문자서비스를 대체하여 발송비용이 없는 푸시 서비스를 사용하실 수 있습니다."
        },
        {
            name: "GPS 솔루션",
            content: "현재위치 정보를 이용한 서비스를 구축하실때 이용하 실 수 있습니다.현재 위치정보 및 주변건물 활용정보, 지도서비스, O2O서비스에 활용하 실 수\n" +
                "            있습니다."
        },
        {
            name: "Beacon(비콘) 솔루션",
            content: "근거리 통신기술인 비콘기능을 활용하여 오프라인과 온라인 서비스의 연동을 쉽게 하실 수 있습니다."
        },
        {
            name: "NFC 솔루션",
            content: "무선태그(RFID)기술 중 하나인 비접속식 통신 기술인 NFC를 활용한 서비스를 활용 하 실 수 있습니다. (Android만 적용가능)"
        },
        {
            name: "갤러리 카메라 솔루션",
            content: "사진찍기 및 사진활용을 하실 수 있는 솔루션입니다. 해당 솔루션을 이용하면, 갤러리안의 사진을 활용하거나, 카메라를 이용한 서비스를 이용하실 수\n" +
                "            있습니다."
        },
        {
            name: "바코드, QR코드 솔루션",
            content: "바코드 및 QR코드의 읽기/쓰기의 기능을 손쉽게 이용하실 수 있습니다. 바코드, QR코드를 생성하거나, 바코드,QR코드를 읽어 서비스에 활용을\n" +
                "            하실 수 있습니다."
        },

        {
            name: "CUSTOM 솔루션",
            content: "원하시는 HARDWARE와의 연동을 블루투스나, WIFI, Networks를 활용하여 CUSTOM하게 맞춰드려 서비스에 이용하실 수 있게\n" +
                "    구성하실 수 있습니다."
        },
]

export default SolutionCardData;



