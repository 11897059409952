import SubVisualRow from "./Components/SmallComponents/SubVisualRow";
import SubNavBusiness from "./Components/SmallComponents/SubNavBusiness";
import {Route, useLocation, useOutletContext} from "react-router-dom";
import {useEffect, useState} from "react";
import {businessDataFunction} from "./Data/BusinessIntroData";
import {Outlet} from "react-router-dom";
import $ from "jquery";
import {gsap} from "gsap";



const BusinessIntro = ()=> {

    let location = useLocation();
    let locationCheck = location.pathname.split("/")[2].trim();
    let [data , setData] = useState(businessDataFunction(locationCheck));



    useEffect(
        ()=> {
            let topImg = $('.sub-visual-img img');
            let tl = gsap.timeline({paused: true});

            tl.to(topImg, {
                'transform-origin': 'center',
                'scale': 1,
                'duration': 2,
                'ease': "expoScale(0.1, 8)"
            }, 'same').to('.sub-visual-title span', {
                opacity: 1,
                y: 0,
                stagger: 0.2,
            }, '-=1.5')

            tl.play();

            {
                let dataCheck = businessDataFunction(locationCheck);
                if(Object.keys(dataCheck) != Object.keys(data)){
                    let dataArray = Object.keys(data);
                    let dataCheckArray = Object.keys(dataCheck);

                    if( dataCheckArray.length < dataArray.length ){

                        let ElementSub = dataArray.filter(x => !dataCheckArray.includes(x));
                        let index = dataArray.indexOf(ElementSub.toString());


                        let copyData = {};

                        for(let key in data){
                            copyData[key] = data[key];
                        }

                        let dataElement = Object.values(data);
                        dataCheck[ElementSub] = dataElement[index];
                     

                    }
                }
            }

            setData(businessDataFunction(locationCheck));

        } ,[location]
    )



    return (
        <div className="subpage-container">
            <Outlet context={{data}}></Outlet>
        </div>
    )

}

export default BusinessIntro;

