import {Link, useLocation} from "react-router-dom";

const SubNavBusiness = () => {
    let location = useLocation();
    let locationCheck = location.pathname.split("/")[2].trim();

    return (
        <div className="sub-nav-row" id="business-nav-tab" data-scroll="true">
            <nav className="sub-nav">
                <ul className="sub-nav-list">
                    <li className={"sub-nav-item " + (locationCheck === 'vehiclecontrol' ? 'active': '')} ><Link to="/businessIntro/vehiclecontrol">차량관제솔루션</Link></li>
                    <li className={"sub-nav-item " + (locationCheck === 'mobileplatform' ? 'active': '')} ><Link to="/businessIntro/mobileplatform">모바일 플랫폼</Link></li>
                    <li className={"sub-nav-item " + (locationCheck === 'agriculturalProductsDistribution' ? 'active': '')} ><Link to="/businessIntro/agriculturalProductsDistribution">농수산유통솔루션</Link></li>
                    <li className={"sub-nav-item " + (locationCheck === 'siIntro' ? 'active': '')} ><Link to="/businessIntro/siIntro">SI</Link></li>
                </ul>
            </nav>
        </div>
    )
}



export default SubNavBusiness;