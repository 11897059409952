
let ArticleImage = (props)=> {
    return (
        <div className="twoapp-img wow fadeInUp">
            <img src={props.subArticleData.data.subArticleData[0].image1 != undefined && props.subArticleData.data.subArticleData[0].image1} alt="" className="pc-only"/>
            <img src={props.subArticleData.data.subArticleData[0].image2 != undefined && props.subArticleData.data.subArticleData[0].image2} alt="" className="mo-only"/>
        </div>
    )
}

export default ArticleImage;