
const FarmdaCardItem = (props)=> {
    return (
        <div className="chacarda-wrap">
            <div className="chacarda-pr-card-row">
                <ul className="chacarda-pr-cards">
                    {
                        props.farmdaCardItemData.map(function (a, i) {
                            return (

                                <li className="chacarda-pr-card-item wow fadeInUp"  key={i}>
                                    <dl className="chacarda-pr-card-desc">
                                        <dt>{props.farmdaCardItemData[i].name}</dt>
                                        <dd>{props.farmdaCardItemData[i].content}</dd>
                                    </dl>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>

    )
}

export default FarmdaCardItem;