import ReactDom, {createPortal} from "react-dom";
import {useEffect, useRef, useState} from "react";

const Portal = ({children,closePortal}) => {
    const ref = useRef(null);
    const [mounted,setMounted] = useState(false);

    useEffect(()=>{
      setMounted(true);
      if(document) {
          const dom = document.getElementById('root');
          ref.current = dom; // ref에 dom 값 전달
      }
    },[]);

    if(ref.current&&mounted) { // mounted 됬고 dom이 존재하는 경우 모달 랜더링 진행
      return createPortal(
          <div className="modal-container">
              <div className="modal-background" role="presentation" onClick={closePortal}/>
              {children}
          </div>,
          ref.current
      )
    }

    return null;
}

export default Portal;