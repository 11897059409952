import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Portal from "./Portal";
import styled from 'styled-components'
import {useCookies, withCookies} from "react-cookie";
import {getCookie, removeCookie, setCookie} from "../../../Components/Cookies";

function Modal ({ className, onClose, maskClosable, closable, visible , modalContent ,setModalVisible ,closeCheck ,setCloseCheck}){
    const onMaskClick = (e) => {
        //target 자식요소
        // currentTarget 부모요소
        if (e.target === e.currentTarget) {
            onClose(e)
        }
    }

    const now = new Date()


    // 하루동안 팝업 닫기
    let Dayclose = (i , e) => {
            const expiry = new Date()
            expiry.setMinutes( expiry.getMinutes() + 12800);
            let cookieName = 'VisitCookie' + i;
            setCookie(cookieName , expiry.getDate() , {path: "/" , expires: expiry});
            close(e);
    }

    let visitList = [];

    if (closeCheck) {
        for (let visibleIdx = 0; visibleIdx < modalContent.length; visibleIdx++) {
            let cookieName = 'VisitCookie' + visibleIdx;
            if(getCookie(cookieName) != null){
                // if(Number(getCookie(cookieName)) <= now){
                //     visitList.push({idx: visibleIdx, booleanCheck: false});
                // } else {
                //     removeCookie(cookieName);
                //     visitList.push({idx: visibleIdx, booleanCheck: true});
                // }
                visitList.push({idx: visibleIdx, booleanCheck: false});
            } else {
                visitList.push({idx: visibleIdx, booleanCheck: true});
            }
        }
    }

    const close = (e) => {
        if (onClose) {
            onClose(e)
        }
        if(visitList[Number(e.target.dataset.idx)] != null){
            visitList.splice(Number(e.target.dataset.idx));
            visitList.push({ idx: Number(e.target.dataset.idx), booleanCheck: false});
        }
        setCloseCheck(false);
        setModalVisible(visitList);
    }

    // if(modalContent.width != null && modalContent.content != null ) {
    if(modalContent.length != 0){

        let portalContent =  modalContent.map(function ( item , i) {

            let idName = "modal-root" + i;

                // (visible[i] !== null && visible[i] !== undefined ) ?  visible[i].idx === i ? false : true :
                return (
                    <Portal elementId={idName} key={i}>
                        <ModalOverlay visible={ (visible[i] !== null && visible[i] !== undefined ) ? visible[i].booleanCheck : (visitList[i] == undefined) ? false : visitList[i].booleanCheck}/>
                        <ModalWrapper
                            className={className}
                            onClick={maskClosable ? onMaskClick : null}
                            tabIndex="-1"
                            visible={ (visible[i] !== null && visible[i] !== undefined ) ? visible[i].booleanCheck : (visitList[i] == undefined) ? false : visitList[i].booleanCheck }
                        >
                            <div tabIndex="0" className="modal-inner" style={{
                                boxSizing: "border-box",
                                position: "relative",
                                width: modalContent[i].width,
                                maxWidth: 700,
                                top: modalContent[i].top,
                                margin: "0 auto",
                                padding: "40px 20px",
                            }}>
                                <ModalInner2>
                                    <div style={{
                                        width: modalContent[i].width,
                                        height: modalContent[i].height,
                                        backgroundColor: "white",
                                        borderTopRightRadius: 15,
                                        borderTopLeftRadius: 15,
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            borderBottomLeftRadius: 15,
                                            borderBottomRightRadius: 15
                                        }} dangerouslySetInnerHTML={{__html: modalContent[i].content}}></div>
                                    </div>
                                    {closable && (
                                        <div style={{
                                            width: modalContent[i].width,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            backgroundColor: "#282828",
                                            padding: 15,
                                            borderBottomLeftRadius: 15,
                                            borderBottomRightRadius: 15,
                                            color: "#ffffff"
                                        }}>
                                            <div style={{
                                                cursor: "pointer",
                                                textAlign: "center",
                                                fontSize: 18,
                                                position: "relative",
                                                left: modalContent[i].width / 6 >= 30 ? 28 : modalContent[i].width / 6
                                            }}
                                                 data-idx={i}
                                                 className="modal-close" onClick={(e) => {
                                                 Dayclose(i, e)
                                            }}>
                                                오늘 하루 닫기
                                            </div>
                                            <div style={{
                                                cursor: "pointer",
                                                textAlign: "center",
                                                fontSize: 18,
                                                position: "relative",
                                                right: modalContent[i].width / 5 >= 30 ? 28 : modalContent[i].width / 5
                                            }}
                                                 className="modal-close" data-idx={i} onClick={close}>
                                                닫기
                                            </div>
                                        </div>
                                    )}
                                </ModalInner2>
                            </div>
                        </ModalWrapper>
                    </Portal>
                )
        })


        return portalContent;
    }
}

Modal.propTypes = {
    visible: PropTypes.array,
}

const ModalInner2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
`


export default React.memo(Modal);


//import {useEffect, useState} from "react";
// import PropTypes from "prop-types";
// import Portal from "./Portal";
// import styled from 'styled-components'
//
// function Modal ({ className, onClose, maskClosable, closable, visible , modalContent}){
//     const onMaskClick = (e) => {
//         if (e.target === e.currentTarget) {
//             onClose(e)
//         }
//     }
//
//     // 이전방문 날짜
//     const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie')
//     // 현재 날짜
//     const VISITED_NOW_DATE = Math.floor(new Date().getDate())
//
//     useEffect(() => {
//         // 팝업 오늘 하루닫기 체크
//         if (VISITED_BEFORE_DATE !== null) {
//             // 날짜가 같을경우 노출
//             if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
//                 localStorage.removeItem('VisitCookie')
//                 onClose(true)
//             }
//             // 날짜가 다를경우 비노출
//             if (VISITED_BEFORE_DATE !== VISITED_NOW_DATE) {
//                 onClose(false)
//             }
//         }
//     }, [VISITED_BEFORE_DATE])
//
//
//     let [cookies , setCookies] = useState([]);
//     // 하루동안 팝업 닫기
//     const Dayclose = (e) => {
//         if (onClose) {
//             onClose(e)
//             const expiry = new Date()
//             // +1일 계산
//             const expiryDate = expiry.getDate() + 1
//             // 로컬스토리지 저장
//             localStorage.setItem('VisitCookie', expiryDate);
//         }
//     }
//
//     const close = (e) => {
//         if (onClose) {
//             onClose(e)
//         }
//     }
//     console.log(modalContent);
//     // if(modalContent.width != null && modalContent.content != null ) {
//     if(modalContent.length != 0){
//         let portalContent =  modalContent.map(function ( item , i) {
//             let idName = "modal-root" + i;
//             return (
//                 <Portal elementId={idName} key={i}>
//                     <ModalOverlay visible={visible}/>
//                     <ModalWrapper
//                         className={className}
//                         onClick={maskClosable ? onMaskClick : null}
//                         tabIndex="-1"
//                         visible={visible}
//                     >
//                         <div tabIndex="0" className="modal-inner" style={{
//                             boxSizing: "border-box",
//                             position: "relative",
//                             width: modalContent[i].width,
//                             maxWidth: 700,
//                             top: modalContent[i].top,
//                             margin: "0 auto",
//                             padding: "40px 20px",
//                         }}>
//                             <ModalInner2>
//                                 <div style={{
//                                     width: modalContent[i].width,
//                                     height: modalContent[i].height,
//                                     backgroundColor: "white",
//                                     borderTopRightRadius: 15,
//                                     borderTopLeftRadius: 15,
//                                     textAlign: "center"
//                                 }}>
//                                     <div style={{
//                                         borderBottomLeftRadius: 15,
//                                         borderBottomRightRadius: 15}}dangerouslySetInnerHTML={{__html: modalContent[i].content}}></div>
//                                 </div>
//                                 {closable && (
//                                     <div style={{
//                                         width: modalContent[i].width,
//                                         display: "flex",
//                                         justifyContent: "space-between",
//                                         backgroundColor: "#282828",
//                                         padding: 15,
//                                         borderBottomLeftRadius: 15,
//                                         borderBottomRightRadius: 15,
//                                         color: "#ffffff"
//                                     }}>
//                                         <div style={{cursor: "pointer", textAlign: "center", fontSize: 18 , position: "relative" , left: modalContent[i].width/6 >= 30 ? 28 : modalContent[i].width/6 }}
//                                              className="modal-close" onClick={Dayclose}>
//                                             오늘 하루 닫기
//                                         </div>
//                                         <div style={{cursor: "pointer", textAlign: "center", fontSize: 18 , position: "relative" , right: modalContent[i].width/5 >= 30 ? 28 : modalContent[i].width/5}}
//                                              className="modal-close" onClick={close}>
//                                             닫기
//                                         </div>
//                                     </div>
//                                 )}
//                             </ModalInner2>
//                         </div>
//                     </ModalWrapper>
//                 </Portal>
//             )
//         })
//
//         return portalContent;
//     }
// }
//
// Modal.propTypes = {
//     visible: PropTypes.bool,
// }
//
//
// const ModalInner2 = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// `
// const ModalWrapper = styled.div`
//     box-sizing: border-box;
//     display: ${(props) => (props.visible ? 'block' : 'none')};
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 1000;
//     overflow: auto;
//     outline: 0;
// `
//
// const ModalOverlay = styled.div`
//     box-sizing: border-box;
//     display: ${(props) => (props.visible ? 'block' : 'none')};
//     position: fixed;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     background-color: rgba(0, 0, 0, 0.6);
//     z-index: 999;
// `
//
//
// export default React.memo(Modal);