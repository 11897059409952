import {MainMapSection} from "../../Main/MainMapSection";
import SubVisualArticle from "./SmallComponent/SubVisualArticle";
import SubNavRow from "./SmallComponent/SubNavRow";
import SubArticleTitle from "./SmallComponent/SubArticleTitle";
import {Link, useOutletContext} from "react-router-dom";


const WaytoCome = () => {

    const { data } = useOutletContext();

    return (
        <>
            <SubVisualArticle name={data.name} imagePC={data.imageNamePC} imageMO={data.imageNameMO}></SubVisualArticle>
            <SubNavRow></SubNavRow>
            <article className="sub-article">
                <div className="sub-article-inner">
                    <SubArticleTitle enName={data.enName} koName={data.koName}></SubArticleTitle>
                    <div className="company-location-wrap">
                        <address>ADDRESS : 경기도 군포시 공단로 140번길 38 센트럴비즈파크 509,510,511호</address>
                        <MainMapSection></MainMapSection>
                        <div className="contact-row">
                            <div className="col-contact col-title">Contact Info</div>
                            <div className="col-contact col-contact-item">
                                <dl>
                                    <dt>전화번호</dt>
                                    <dd><a href="tel:1661-4857">1661-4857</a></dd>
                                </dl>
                            </div>
                            <div className="col-contact col-contact-item">
                                <dl>
                                    <dt>이메일</dt>
                                    <dd><a href="mailto:help@erst.kr">help@erst.kr</a></dd>
                                </dl>
                            </div>
                            <div className="col-contact col-contact-item">
                                <dl>
                                    <dt>고객센터</dt>
                                    <dd>평일 09:00 - 18:00</dd>
                                </dl>
                            </div>
                            <div className="col-contact col-contact-item">
                                <Link className="text-link"  to="/customer/qna"><button type="button"
                                        className="vbtn btn-line-black-black large btn-contact-form">문의하기
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </>


)
}

export default WaytoCome;