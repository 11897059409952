
const SubVisualRow = (props)=> {
    return (
        <section className="sub-visual-row">
            <article className="sub-visual-article">
                <ul className="breadcrumbs">
                    <li><a href="/Users/ijaeyeong/Desktop/react/test1/src/Pages">HOME</a></li>
                    <li className="icon"><img src="../../images/icon-right-triangle.svg" alt=""/></li>
                    <li>사업 소개</li>
                    <li className="icon"><img src="../../images/icon-right-triangle.svg" alt=""/></li>
                    <li className="current">{props.current}</li>
                </ul>
                <div className="sub-visual-title">
                    <h2 className="title">
                        <span lang="en">BUSINESS</span>
                        <span lang="ko">사업 소개</span>
                    </h2>
                </div>
            </article>
            <div className="sub-visual-img">
                <div className="inner">
                    <img src="../../images/sub-visual-chacarda-pc.jpg" alt="" className="pc-only"/>
                        <img src="../../images/sub-visual-chacarda-mo.jpg" alt="" className="mo-only"/>
                </div>
            </div>
        </section>
    )
}

export default SubVisualRow;