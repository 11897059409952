import {Link} from "react-router-dom";

export const MainPRSection = () => {
    return (
        <section className="main-pr-section">
            <div className="inner layout1">
                <div className="main-pr-copy wow fadeInUp">
                    <h3 className="main-pr-title">이알솔루션은 IT기술을 기반으로 <em>지속적인 혁신</em>을 통해<br/> <em>고객을 만족</em>시키는 기업 입니다.</h3>
                    <p className="main-pr-text">이알솔루션은 차량관제, 모바일플랫폼, 농수산유통 솔루션 및 IT 서비스/<br className="pc-only"/>시스템 구축 전문 IT
                        기반 업체로서 빅데이터, AI 등 신성장동력에 사업을<br className="pc-only"/> 박차고 있는 기업입니다.</p>
                    <Link className="vbtn btn-company-introduction" to="/companyIntro/companyInfo">COMPANY INTRODUCTION <span
                        className="ico-arrow"><i className="ri-arrow-right-line"></i></span></Link>
                </div>
                <div className="main-pr-img wow slideInUp" data-wow-duration="1.5s">
                    <img src="/images/main-pr-introillust.png" alt=""/>
                </div>
            </div>
        </section>
    );
}