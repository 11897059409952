

let ChacardaData = [
        {
          name: "실시간 차량위치",
          content: "실시간 차량위치 모니터링 하여 확인가능"
        },
        {
            name: "경제/안전운전 관리",
            content: "리포트를 제공하여 경제/안전운전 관리 차량 운영 관리 용이(급가속, 급감속, 급회전, 급추월 등)"
        },
        {
            name: "ETAS 제출 제공",
            content: "운행기록계(DTG)의 운행일지를 자동으로 제출 할 수 있는 시스템 연계 제공, 버스/화물 및 어린이안심차량 대응"
        },

        {
            name: "LG 헬로모바일(통신향)",
            content: "LG헬로 모바일과의 파트너쉽 제휴로 통신향 모델을 합리적인 가격으로 제공"
        },

        {
            name: "배터리 관제",
            content: "배터리의 전압관련 관제 모니터링 기능 제공"
        },

        {
            name: "온도관제",
            content: "냉동/냉장 차량의 실시간 온도관제 제공(추가센서필요)"
        },

        {
            name: "전용 APP 제공",
            content: "차카다만을 위한 전용 APP을 제공하여 사용성 증대"
        },

        {
            name: "센터(지점)등록",
            content: "무제한 센터(지점)등록 제공 하여 여러차량을 손쉽게 관리"
        },


    ]

export default ChacardaData;






