import Map from "../../Components/Map";
import {useEffect} from "react";

export const MainMapSection = () => {

    useEffect( ()=> {
           Map();
    },[]);
    return (
        <section className="main-map-section">
            <div id="common-contact-map" className="common-map"></div>
        </section>
    )
}