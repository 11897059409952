import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


export const NoticeSection = () => {

    let navigate = useNavigate();
    let [noticeList , setNoticeList] = useState([]);

    useEffect(
        ()=>{
            Promise.all([
                axios.get(
                    process.env.REACT_APP_REST_URL+'/user/notice/mainList'
                )]).then((data) => {
                    if(data[0].data.response != null) {
                        setNoticeList(data[0].data.response);
                    } else {
                        let noticeEmptyList = [];
                        noticeEmptyList.push({
                            noticeNumber: -1,
                            subject: data[0].data.message,
                            rdate: null
                        })
                        setNoticeList(noticeEmptyList);
                    }
                }
            )
        } ,[]
    )


    return (
        <section className="main-notification-section">
            <div className="notification-swiper-row">
                <div className="notification-swiper">
                    <div className="swiper-wrapper notification-swiper-wrapper">

                            {
                                noticeList.map(function (a , i) {
                                    let id = noticeList[i].noticeNumber;
                                    return (
                                        <div className="swiper-slide"key={i}>
                                            {id != -1 ?
                                            <a onClick={ ()=> {navigate("/customer/notice/detail/"+id)}} className="notification-swiper-link" >
                                                <span className="label">NOTICE</span>
                                                <span className="text">{noticeList[i].subject}</span>
                                                <span className="date">{noticeList[i].rdate.substring(0 ,noticeList[i].rdate.indexOf(" "))}</span>
                                            </a> :
                                            <a onClick={ ()=> {navigate("#")}} className="notification-swiper-link" >
                                                <span className="label">NOTICE</span>
                                                <span className="text">{noticeList[i].subject}</span>
                                                <span className="date">{noticeList[i].rdate}</span>
                                            </a>
                                            }
                                        </div>
                                    )
                                })
                            }

                    </div>
                </div>
                <div className="swiper-buttons">
                    <button type="button" className="vbtn swiper-button-prev notification-button-prev"></button>
                    <button type="button" className="vbtn swiper-button-next notification-button-next"></button>
                </div>
            </div>
        </section>
    );
}