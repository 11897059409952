
let SolutionCardItem = (props) => {

    return (
           props.cardItem.map(function( a , i){
                return(
                    <li className="solution-card-item wow fadeInUp" key={i}>
                        <dl className="solution-card-desc">
                            <dt>{props.cardItem[i].name}</dt>
                            <dd>{props.cardItem[i].content}
                            </dd>
                        </dl>
                    </li>

                )
            })
    )
}

export default SolutionCardItem;