import dongwonimg from "../../../images/app/img_web1.png";
import bhlimg from "../../../images/app/img_web2.png";
import minyoungimg from "../../../images/app/img_web3.png";
import ebeimg from "../../../images/app/img_web4.png";
import rusiimg from "../../../images/app/img_web5.png";
import tireimg from "../../../images/app/img_web6.png";
let SiWebBoardListData = [
    {
        id: "dongwon",
        title: "홈페이지 제작",
        text: "동원 F&B 재치영양사",
        image: dongwonimg
    },

    {
        id: "bhl",
        title: "홈페이지 제작",
        text: "BHL",
        image: bhlimg
    },

    {
        id: "minyoung",
        title: "홈페이지 제작",
        text: "민영상사 BMS시스템",
        image: minyoungimg
    },

    {
        id: "ebe",
        title: "홈페이지 제작",
        text: "이배려식",
        image: ebeimg
    },
    {
        id: "rusi",
        title: "홈페이지 제작",
        text: "루시맥스 영어교육 시스템",
        image: rusiimg
    },

    {
        id: "tire",
        title: "홈페이지 제작",
        text: "한국타이어 웹오더 시스템",
        image: tireimg
    }

]
export default SiWebBoardListData;

