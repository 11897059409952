
const EtcCaseItem = (props)=> {
    return(
        props.caseItem.map(function (a,i){
            return(
                <li className="etc-case-item wow fadeInUp" key={i}>
                    <div className="card">{props.caseItem[i].name}</div>
                </li>
            )
        })
    )
}





export default EtcCaseItem;