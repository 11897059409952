import {Link, useLocation} from "react-router-dom";

const Footer = () => {

    let location = useLocation();

    let pathName = location.pathname;
    if( pathName.trim().startsWith("/popup/")){
        return null
    } else {
        return (
            <footer>

                <div className="foot-addr-row">

                    <div className="foot-addr-col foot-addr-logo">
                        <div className="info-row"><img src="../../images/logo_FOOTER.png" alt="ERsolution"/></div>
                    </div>
                    <div className="foot-addr-col foot-addr-col">
                        <div className="info-row"><span className="label">사업자등록번호</span> 652-86-00144
                        </div>
                        <div className="info-row"><span className="label">대표이사</span> 이길용</div>
                    </div>
                    <div className="foot-addr-col foot-addr-col">
                        <div className="info-row">
                            <address><span className="label">ADDRESS</span> 경기도 군포시 공단로 140번길 38 센트럴비즈파크 509,510,511호
                            </address>
                        </div>
                        <div className="info-row info-row-tel">
                            <span className="label">TEL</span> <a href="tel:1661-4857">1661-4857</a>
                            <span className="label">FAX</span> 0507-1799-8248
                            <span className="label">EMAIL</span> <a href="mailto:help@erst.kr">help@erst.kr</a>
                        </div>
                    </div>

                </div>

                <div className="foot-nav-row">
                    <nav className="foot-nav">
                        <ul className="foot-nav-list">
                            <li className="foot-nav-item"><Link to="/companyIntro/waytocome">찾아오시는길</Link></li>
                            <li className="foot-nav-item"><a href="tel:1661-4857">고객지원센터 <em>1661-4857</em></a>
                            </li>
                            <li className="foot-nav-item"><a href="mailto:help@erst.kr">이메일 <em>help@erst.kr</em></a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className="foot-copyright">COPYRIGHT © 2022 ERSOLUTION CO., LTD ALL RIGHTS RESERVED.</div>

            </footer>

        );
    }
}

export default Footer;