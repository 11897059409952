import zeroDay from "../../../images/img-zeroday-mockup.jpg";
import iCoop from "../../../images/img-icoop-mockup.jpg";
let TopCaseItemData = [
    {
        title: "SHINSEGAECENTRAL ZERODAY",
        text1: "신세계 센트럴 제로데이",
        text2: "신세계 센트럴 발주건으로 고속버스터미널을 이용하여 택배를 주고 받을 수 있는\n" +
            "            서비스 앱 입니다.",
        image: zeroDay
    },

    {
        title: "ICOOP APP",
        text1: "아이쿱",
        text2: "아이쿱의 식품을 배송 관제 할 수 있는 서비스를 탑재한 앱입니다",
        image: iCoop
    },
]

export default TopCaseItemData;
