

const ChacardaCardItem = (props)=> {
    return(
        props.cardItem.map(function (a , i){
            return(
                <li className="chacarda-pr-card-item wow fadeInUp" key={i}>
                    <dl className="chacarda-pr-card-desc">
                        <dt>{props.cardItem[i].name}</dt>
                        <dd>{props.cardItem[i].content}</dd>
                    </dl>
                </li>
            )
        })
    )
}

export default ChacardaCardItem;