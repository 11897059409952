import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const MissingPage = ()=>{
    let navigate = useNavigate();
    alert("존재하지 않는 페이지 입니다.");


    useEffect( ()=> {
            navigate(-1)
    }, [])


}

export default MissingPage;