import Header, {setShowOption} from "./Components/Header";
import Main from "./Pages/Main/Main";
import Footer from "./Components/Footer";
import CompanyInfo from "./Pages/CompanyInfo/Components/Companyinfo";
import Years from "./Pages/CompanyInfo/Components/Years";
import Organization from "./Pages/CompanyInfo/Components/Organization";
import WaytoCome from "./Pages/CompanyInfo/Components/WaytoCome";
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import VehicleControl from "./Pages/BusinessIntro/Components/VehicleControl";
import MobilePlatform from "./Pages/BusinessIntro/Components/MobilePlatform";
import AgriculturalProductsDistribution from "./Pages/BusinessIntro/Components/AgriculturalProductsDistribution";
import Si from "./Pages/BusinessIntro/Components/Si";
import Notice from "./Pages/customer/Notice";
import NoticeDetail from "./Pages/customer/NoticeDetail";
import CompanyInfos from "./Pages/CompanyInfo/CompanyInfos";
import {useEffect , useState} from "react";
import $ from "jquery";
import {gsap} from "gsap";
import BusinessIntro from "./Pages/BusinessIntro/BusinessIntro";
import MissingPage from "./Pages/404/MissingPage";
import CustomerQnA from "./Pages/customer/CustomerQnA";
import {history} from "./Components/History";
import WindowPopup from "./Pages/Main/Popup/WindowPopup";






function App() {

    let Navigate = useNavigate();

    let [locationBoolean , setLocationBoolean] = useState(false);
    let location = useLocation();

    useEffect(() => {
        const listenBackEvents = () => {
        };
        const unlistenHistoryEvents = history.listen(({ action }) => {
            if (action === "POP") {

                listenBackEvents();
            }
        });

        return unlistenHistoryEvents;
    },[]);


    useEffect(()=>{

        let topImg = $('.sub-visual-img img');
        let tl = gsap.timeline({paused: true});

        tl.to(topImg, {
            'transform-origin': 'center',
            'scale': 1,
            'duration': 2,
            'ease': "expoScale(0.1, 8)"
        }, 'same').to('.sub-visual-title span', {
            opacity: 1,
            y: 0,
            stagger: 0.2,
        }, '-=1.5')
        tl.play();
        setLocationBoolean(false);
        return(()=>{
            setLocationBoolean(false);
        })
    } , [location])


      return (
          <div id="wrap">

            <Header Navigate={Navigate} locationBoolean={locationBoolean}></Header>
              <Routes>
                  <Route path="/" element={<Main></Main>}/>

                  <Route path="companyIntro"element={<CompanyInfos/>}>
                      <Route path="companyInfo" element={<CompanyInfo/>}></Route>
                      <Route path="years" element={<Years/>}></Route>
                      <Route path="organization" element={<Organization/>}></Route>
                      <Route path="waytocome" element={<WaytoCome/>}></Route>
                  </Route>

                  <Route path="/businessIntro" exact={true} element={<BusinessIntro/>}>
                      <Route path="vehiclecontrol" element={<VehicleControl/>}></Route>
                      <Route path="mobileplatform"  element={<MobilePlatform/>}></Route>
                      <Route path="agriculturalProductsDistribution" element={<AgriculturalProductsDistribution/>}></Route>
                      <Route path="siIntro" element={<Si/>}></Route>
                  </Route>
                  <Route path="/customer/notice/detail/:id" element={<NoticeDetail/>}/>
                  <Route path="/customer/notice"  exact={true} element={<Notice/>}/>
                  <Route path="/customer/qna"  exact={true} element={<CustomerQnA/>}/>
                  <Route path="*" element={<MissingPage/>}  />
                  <Route path="/popup/:popupNumber"  element={<WindowPopup/>}/>
              </Routes>
            <Footer></Footer>
          </div>
      );

}


export default App;
