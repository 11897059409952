

export const MainSection = () => {
    return (
        <section className="main-visual-section">
        <div className="main-visual-swiper">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="copy-box">
                        <p className="copy1" >Innovation &amp; Challenge</p>
                        <p className="copy2" >이알솔루션의<br/> 새로운 <em>혁신</em>, 그리고 <em>도전</em></p>
                        <p className="copy3" >열정이 차있는 이알솔루션이 여러분과 함께<br/>새로운 혁신에 도전하겠습니다</p>
                    </div>
                    <div className="main-visual-img">
                        <img src="/images/main-visual01-pc.jpg" alt="" className="pc-only"/>
                        <img src="/images/main-visual01-mo.jpg" alt="" className="mo-only"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}