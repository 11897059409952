import SubNavRow from "./SmallComponent/SubNavRow";
import {useEffect} from "react";
import $ from "jquery";
import {gsap} from "gsap";
import SubVisualArticle from "./SmallComponent/SubVisualArticle";
import SubArticleTitle from "./SmallComponent/SubArticleTitle";
import {useOutletContext} from "react-router-dom";



const Organization = ()=> {

    const { data } = useOutletContext();

    return (
        <>
            <SubVisualArticle name={data.name} imagePC={data.imageNamePC} imageMO={data.imageNameMO}></SubVisualArticle>
            <SubNavRow></SubNavRow>
            <article className="sub-article">
                <div className="sub-article-inner">
                    <SubArticleTitle enName={data.enName} koName={data.koName}></SubArticleTitle>
                    <div className="company-organization-wrap">
                        <img src="/images/organization-table.svg" alt="" className="wow fadeIn"
                             data-wow-delay="0.5s"/>

                            <div className="blind">
                                ceo 대표이사 > 관리부, 개발부(개발팀, 디자인팀), 기업부설연구소, 기획 영업팀
                            </div>
                    </div>
                </div>
            </article>
        </>
    )
}

export default Organization;