

let EffectCardBoxData = [
        {
            bigEffect: "비용절감효과",
            firstEffect: "최소 제작 인력으로 제작비용감소",
            secondEffect: "모바일OS 업데이트 대응 으로 인한 유지보수 비용 절감"
        },

        {
            bigEffect: "유지관리용이",
            firstEffect: "하나의 front source로 다중OS 플랫폼의 대응 가능",
            secondEffect: "앱 업데이트 최소화, front source의 수정은 컨텐츠 영역에 바로 적용되어 잦은 앱 업데이트 필요성 감소"
        },

        {
            bigEffect: "앱 제작의 생산효율성 향상",
            firstEffect: "서비스별 특화된 솔루션 기능으로 앱 제작의 효율성 강화",
            secondEffect: "Javascript 소스로 제어가 가능하므로 제작 및 소스관리가 용이"
        },

]


export default EffectCardBoxData;


