
import ChacardaCardItem from "./SmallComponents/ChacardaCardItem";
import ChacardaCarditemData from "../Data/ChacardaCarditemData";
import {useState} from "react";
import ArticleTitle from "./SmallComponents/Article/ArticleTitle";
import ArticleText from "./SmallComponents/Article/ArticleText";
import ArticleImage from "./SmallComponents/Article/ArticleImage";
import {useOutletContext} from "react-router-dom";
import SubVisualRow from "./SmallComponents/SubVisualRow";
import SubNavBusiness from "./SmallComponents/SubNavBusiness";

const VehicleControl = ()=> {

    let [cardItem] = useState(ChacardaCarditemData);
    const { data } = useOutletContext();

    if(data.subArticleData != null || data.subArticleData != undefined) {
        return (
            <>
                <SubVisualRow current={data.current}></SubVisualRow>
                <SubNavBusiness></SubNavBusiness>
                <article className="sub-article">
                    <div className="sub-article-inner">
                        <div className="sub-article-title type2 wow fadeInUp">
                            <ArticleTitle subArticleData={{data}}></ArticleTitle>
                            <ArticleText subArticleData={{data}}></ArticleText>
                        </div>
                        <ArticleImage subArticleData={{data}}></ArticleImage>
                    </div>
                </article>
                <div className="chacarda-wrap">
                    <div className="chacarda-pr-card-row">
                        <ul className="chacarda-pr-cards">
                            <ChacardaCardItem cardItem={cardItem}></ChacardaCardItem>
                        </ul>
                    </div>
                </div>

                <article className="sub-article sub-article-system">
                    <div className="sub-article-inner">

                        <div className="sub-article-title2">
                            <h4 className="title wow fadeInUp">시스템 진행 원리</h4>
                        </div>

                        <div className="chacarda-system">
                            <ul className="chacarda-system-list">
                                <li className="chacarda-system-item wow fadeInUp">
                                    <div className="chacarda-system-card">
                                        <p className="text1">차량에 설치된 단말에서<br className="pc-only"/> GPS 데이터 수집</p>
                                        <div className="img"><img src="/images/img-system1.svg" alt=""/></div>
                                        <p className="text2">차량관제단말<br/> (LTE 통신모듈 포함)</p>
                                        <p className="text3">차량위치, 운행거리, 운행속도<br/>차량상태 정보 전달</p>
                                    </div>
                                </li>
                                <li className="chacarda-system-item wow fadeInUp">
                                    <div className="chacarda-system-card">
                                        <p className="text1">LTE 통신망을 통해<br className="pc-only"/> 관제서버로 전달</p>
                                        <div className="img"><img src="/images/img-system2.svg" alt=""/></div>
                                        <p className="text2">관제서버 확인</p>
                                        <p className="text3">차량위치, 운행거리, 운행속도<br/> 차량상태 정보 전달</p>
                                    </div>
                                </li>
                                <li className="chacarda-system-item wow fadeInUp">
                                    <div className="chacarda-system-card">
                                        <p className="text1">고객사에서 PC와 모바일로<br className="pc-only"/> 확인 가능</p>
                                        <div className="img"><img src="/images/img-system3.svg" alt=""/></div>
                                        <p className="text2">WEB, APP, 고객사</p>
                                        <p className="text3">차량운행 현황, 운행경로 확인, <br/>안전/결제운전 모니터링 정보 제공</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="sub-article-title2">
                            <h4 className="title wow fadeInUp">차량관제솔루션 제품 소개</h4>
                        </div>
                        <div className="chacarda-prd">
                            <ul className="chacarda-prds">
                                <li className="chacarda-prd-item wow fadeInUp">
                                    <dl>
                                        <dt className="prd-name">· LDT-300BS</dt>
                                        <dd className="prd-img"><img src="/images/chacarda-prd-LDT-300BS.png" alt=""/>
                                        </dd>
                                    </dl>
                                </li>
                                <li className="chacarda-prd-item wow fadeInUp">
                                    <dl>
                                        <dt className="prd-name">· GD-1000</dt>
                                        <dd className="prd-img"><img src="/images/chacarda-prd-GD-1000.png" alt=""/>
                                        </dd>
                                    </dl>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </>
        )
    } else {
        return null;
    }
}





export default VehicleControl;