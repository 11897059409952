import SolutionCardItem from "./SmallComponents/SolutionCardItem";
import SolutionCardItemData from "../Data/SolutionCardItemData";
import EtcData from "../Data/EtcCaseItemData";
import EffectCardBoxData from "../Data/EffectCardBoxData";
import {useState} from "react";
import EtcCaseitem from "./SmallComponents/EtcCaseitem";
import EffectCardBox from "./SmallComponents/EffectCardBox";
import TopCaseItem from "./SmallComponents/TopCaseItem";
import TopCaseItemData from "../Data/TopCaseItemData";
import {useOutletContext} from "react-router-dom";
import ArticleTitle from "./SmallComponents/Article/ArticleTitle";
import ArticleText from "./SmallComponents/Article/ArticleText";
import ArticleImage from "./SmallComponents/Article/ArticleImage";
import SubVisualRow from "./SmallComponents/SubVisualRow";
import SubNavBusiness from "./SmallComponents/SubNavBusiness";

const MobilePlatform = ()=> {

    let [cardData] = useState(SolutionCardItemData);
    let [etcData] = useState(EtcData);
    let [topCaseItemData] = useState(TopCaseItemData);
    let [effectCardBoxData] = useState(EffectCardBoxData);

    const { data } = useOutletContext();
    if(data.subArticleData != null || data.subArticleData != undefined) {
        return (
            <>
                <SubVisualRow current={data.current}></SubVisualRow>
                <SubNavBusiness></SubNavBusiness>
                <article className="sub-article">
                    <div className="sub-article-inner">
                        <div className="sub-article-title type2 sub-article-title-mobile-flatform wow fadeInUp">
                            <ArticleTitle subArticleData={{data}}></ArticleTitle>
                            <ArticleText subArticleData={{data}}></ArticleText>
                        </div>
                        <ArticleImage subArticleData={{data}}></ArticleImage>
                    </div>
                </article>

                <div className="twoapp-wrap">
                    <div className="twoapp-wrap-inner">
                        <div className="sub-article-title2 wow fadeInUp">
                            <h4 className="title">서비스 소개</h4>
                        </div>
                        <ul className="twoapp-service-intro-text">
                            <li className="twoapp-service-intro-text-item wow fadeInUp">TWOAPP은 모바일 서비스를 위한 앱제작을 손쉽게 할 수
                                있게
                                도와주는<br className="pc-only"/> 플랫폼 서비스 입니다.
                            </li>
                            <li className="twoapp-service-intro-text-item wow fadeInUp">기존 웹 제작 인력을 그대로 활용하면서 앱 제작을 할 수
                                있습니다. <br className="pc-only"/>(twoapp lite version, twoapp pro version)
                            </li>
                            <li className="twoapp-service-intro-text-item wow fadeInUp">앱고유의 기능을 범용적으로 활용하실 수 있습니다.<br
                                className="pc-only"/> (ex. PUSH, GPS, HARDWARE connect, OFFICE Viewer 등)
                            </li>
                            <li className="twoapp-service-intro-text-item wow fadeInUp">앱스토어, 구글플레이, 원스토어의 배포뿐만 아니라 커스텀
                                스토어를
                                통한<br className="pc-only"/> 배포가 가능합니다.
                            </li>
                        </ul>
                        <ul className="twoapp-service-intro-cards">
                            <li className="twoapp-service-intro-card-item wow fadeInUp">
                                <div className="twoapp-service-intro-card">Mobile <span className="arrow"></span></div>
                            </li>
                            <li className="twoapp-service-intro-card-item wow fadeInUp">
                                <div className="twoapp-service-intro-card">TWOAPP<br/>Platform</div>
                            </li>
                            <li className="twoapp-service-intro-card-item wow fadeInUp">
                                <div className="twoapp-service-intro-card">
                                    <p>배포<br/>(<span>APP store,<br/>google play store,<br/>One store,<br/>Custom store</span>)
                                    </p></div>
                            </li>
                            <li className="twoapp-service-intro-card-item wow fadeInUp">
                                <div className="twoapp-service-intro-card">Push,<br/>GPS,<br/>하드웨어연동,<br/>Office
                                    viewer<br/>……
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="twoapp-diagram-wrap">
                    <article className="sub-article">
                        <div className="sub-article-inner">
                            <div className="sub-article-title2">
                                <h4 className="title wow fadeInUp">구성도</h4>
                                <p className="text wow fadeInUp">TWOAPP 플랫폼은 서비스를 위한 최적의 구성에 맞추어 제작해드릴 수 있습니다.</p>
                            </div>
                            <div className="diagram-row wow fadeInUp">
                                <div className="diagram-col">
                                    <div className="diagram-card">
                                        <ul className="diagram-cards">
                                            <li className="diagram-card-item">
                                                <div className="block">SFTP(FTP)<br/>Service<br/>open</div>
                                            </li>
                                            <li className="diagram-card-item">
                                                <div className="block">TWOAPP<br/>Server</div>
                                            </li>
                                            <li className="diagram-card-item">
                                                <div className="block">TWOAPP<br/>Platform</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="title">&lt;TWOAPP Lite Version&gt;</p>
                                </div>
                                <div className="diagram-col">
                                    <div className="diagram-card">
                                        <ul className="diagram-cards">
                                            <li className="diagram-card-item">
                                                <div className="block">Customer Server <small>(AWS, KT Colude, IDC
                                                    Server)</small></div>
                                            </li>
                                            <li className="diagram-card-item">
                                                <div className="block">TWOAPP<br/>Platform</div>
                                            </li>
                                            <li className="diagram-card-item">
                                                <div className="block">Developer Kit<br/>(Intellij)</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="title">&lt;TWOAPP Pro Version&gt;</p>
                                </div>
                            </div>
                            <div className="sub-article-title2">
                                <h4 className="title wow fadeInUp">기대효과</h4>
                            </div>
                            <div className="effect-card-box">
                                <EffectCardBox effectCardBoxData={effectCardBoxData}></EffectCardBox>
                            </div>
                        </div>
                    </article>
                </div>
                <div className="twoapp-solution-wrap">
                    <article className="sub-article">
                        <div className="sub-article-inner">
                            <div className="sub-article-title2">
                                <h4 className="title wow fadeInUp">솔루션</h4>
                            </div>
                            <ul className="solution-cards">
                                <SolutionCardItem cardItem={cardData}></SolutionCardItem>
                            </ul>
                        </div>
                    </article>
                </div>
                <div className="twoapp-application-case-wrap">
                    <article className="sub-article">
                        <div className="sub-article-inner">
                            <div className="sub-article-title2">
                                <h4 className="title">적용사례</h4>
                            </div>
                            <div className="top-case">
                                <ul className="top-case-list">
                                    <TopCaseItem topCaseItem={topCaseItemData}></TopCaseItem>
                                </ul>
                            </div>
                            <div className="etc-case">
                                <p className="etc-case-title wow fadeInUp">그외 제작 사례</p>
                                <ul className="etc-case-list">
                                    <EtcCaseitem caseItem={etcData}></EtcCaseitem>
                                </ul>
                            </div>
                        </div>
                    </article>
                </div>
            </>
        )
    } else {
        return null;
    }
}



export default MobilePlatform;