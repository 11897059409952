
let FarmdaCardItemData = [
    {
        name: "쇼핑몰제공",
        content: "농산물 전용 쇼핑몰 주소를 별도로 발급하여 제공합니다."
    },

    {
        name: "오픈마켓/종합몰",
        content: "옥션, G마켓, 11번가, 네이버스마트스토어, 카카오쇼핑등 오픈마켓 및 종합몰 입점 제공"
    },

    {
        name: "주문통합관리",
        content: "모든 주문을 통합하여 관리할 수 있는 솔루션 제공"
    },

    {
        name: "클레임 관리",
        content: "각종 클레임 및 CS 고객센터의 클레임관리를 아웃소싱합니다.(별도 요금제가입)"
    },

    {
        name: "운송장 자동출력",
        content: "운송장 자동출력기능 제공으로 농산물의 품질에만 신경쓰실 수 있도록 합니다."
    },

    {
        name: "농산물 빅데이터",
        content: "내가 팔고 있는 농산물의 도매가/소매가를 매일 알 수 있도록 제공합니다."
    },

    {
        name: "전용 APP 제공",
        content: "FARMDA 전용 APP을 제공하여 주문수발주를 실시간으로 확인하실 수 있습니다."
    },

    {
        name: "알림 서비스 제공",
        content: "오늘의 주문/취소/클레임등을 확인 하실수 있는 알림서비스를 제공합니다."
    },

]
export default FarmdaCardItemData;




