import CustomerNavTab from "./Component/CustomerNavTab";
import axios from "axios";
import {createRef, useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";

let CustomerQnA =  ()=>{
    const url = process.env.REACT_APP_LOCAL_QNA_LOCATION;
    const [imgBase64, setImgBase64] = useState([]); // 파일 base64
    const [imgFile, setImgFile] = useState({});	//파일
    const maxSize = 10485750;
    const [disable, setDisable] = useState(true);

    /**
     * 파일 올리기
     * @param event
     * @returns {boolean}
     */
    const handleChangeFile = (event) => {

        setImgFile(event.target.files);
        setImgBase64([]);


        for (let i = 0; i < event.target.files.length; i++) {
            if(event.target.files[i].size >= maxSize){

                alert("파일 사이즈가 큽니다. 10메가 이하 가능");
                return false;
            } else {
                if (event.target.files[i]) {
                    let reader = new FileReader();
                    if (event.target.files[i].name.split(".").pop() == "png" || event.target.files[i].name.split(".").pop() == "jpg" || event.target.files[i].name.split(".").pop() == "gif" || event.target.files[i].name.split(".").pop() == "jpeg") {
                        reader.readAsDataURL(event.target.files[i]);

                        reader.onloadend = () => {
                            const base64 = reader.result;
                            if (base64) {
                                let base64Sub = base64.toString();
                                setImgBase64(imgBase64 => [...imgBase64, base64Sub]);
                            }
                        }
                    } else {
                        setImgBase64(imgBase64 => [...imgBase64, "false"]);
                    }
                }
            }
        }
    }

    let [checkDelete , setCheckDelete] = useState(false);
    /**
     *  파일 사이즈 에러
     * @returns {*[]}
     */
    const fileRender = () => {
        const result = [];
        for (let i = 0; i < imgFile.length; i++) {
            if(imgFile[i].size >= maxSize) {
                result.push(<span style={{fontSize: 13}} key={i}> 파일 사이즈가 큽니다. 이대로 업로드를 진행하신다면 업로드가 되지 않습니다. 파일명 : {imgFile[i].name}  <Button variant="outline-black"
                                          size="sm" onClick={() => {
                    fileDeleteReload(i)
                }}>X</Button></span>);
            } else {
                result.push(<span style={{fontSize: 13}} key={i}>{imgFile[i].name} <Button variant="outline-black"
                                                                                           size="sm" onClick={() => {
                    fileDeleteReload(i)
                }}>X</Button></span>);
            }
        }
        return result;
    };
    /**
     * 파일 빼기
     * @param i
     */
    const fileDeleteReload = (i)=>{
        if(imgFile != null && imgFile != undefined){
            let copy = [...imgFile]
            copy.splice( i , 1);
            setImgFile(copy);
            setCheckDelete(!checkDelete)
        }
    }

    useEffect(()=>{
        if(imgFile != null || imgFile != undefined) {
            fileImages();
        }
    } , [checkDelete])
    /**
     * 이미지 비교
     * @returns {boolean}
     */
    const fileImages = ()=>{
        setImgBase64([])
        for (let i = 0; i < imgFile.length; i++) {
            if(imgFile[i].size >= maxSize) {
                return false;
            } else {
                if (imgFile[i].name.split(".").pop() == "png" || imgFile[i].name.split(".").pop() == "jpg" || imgFile[i].name.split(".").pop() == "gif" || imgFile[i].name.split(".").pop() == "jpeg") {
                    if (imgFile[i]) {
                        let reader = new FileReader();
                        reader.readAsDataURL(imgFile[i]);
                        reader.onloadend = () => {
                            const base64 = reader.result;
                            if (base64) {
                                let base64Sub = base64.toString()
                                setImgBase64(imgBase64 => [...imgBase64, base64Sub]);
                            }
                        }
                    }
                } else {
                    setImgBase64(imgBase64 => [...imgBase64, "false"]);
                }
            }
        }
    }

    /**
     * QnA 요청
     * @returns {Promise<boolean>}
     */
    const requestQnA = async ()=> {
        const formData = new FormData();

        if(document.getElementById("name").value == null || document.getElementById("name").value == ""){

            return false;
        }
        let files = Object.values(imgFile);
        files.forEach((file, index) => files[index].size >= maxSize && files.splice(index, 1));
        files.forEach((file) => formData.append('file', file));

        formData.append(
            'name', document.getElementById("name").value
        );
        formData.append(
            'email', document.getElementById("email").value
        );
        formData.append(
            'content' , document.getElementById("qna-contents").value
        );

        await axios.post(
            url , formData , {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        ).then((data)=>{
            if(data.data.result == 200){
                alert("고객 문의가 정상적으로 처리 되었습니다.");
                window.location.href = "/";
            } else {
                alert("고객 문의가 정상적으로 처리 되지 않았습니다. 관리자에게 문의해주세요.");
            }
        })
    }


    /**
     *  파일 업로드
     * @returns {Promise<void>}
     */
    const requestQnAnotFile = async ()=> {
        const formData = new FormData();

        formData.append(
            'name', document.getElementById("name").value
        );
        formData.append(
            'email', document.getElementById("email").value
        );
        formData.append(
            'content' , document.getElementById("qna-contents").value
        );

        await axios.post(
            url , formData , {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        ).then((data) =>{
            if(data.data.result == 200){
                alert("고객 문의가 정상적으로 처리 되었습니다.");
            } else {
                alert("고객 문의가 정상적으로 처리 되지 않았습니다. 관리자에게 문의해주세요.");
            }
        })
    }

    return(
    <div className="subpage-container">
        <section className="sub-visual-row">
            <article className="sub-visual-article">
                <ul className="breadcrumbs">
                    <li><a href="/">HOME</a></li>
                    <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                    <li>고객센터</li>
                    <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                    <li className="current">고객문의</li>
                </ul>

                <div className="sub-visual-title">
                    <h2 className="title">
                        <span lang="en">ERSOLUTION</span>
                        <span lang="ko">고객센터</span>
                    </h2>
                </div>
            </article>

            <div className="sub-visual-img">
                <div className="inner">
                    <img src="/images/sub-visual-qna-pc.jpg" alt="" className="pc-only"/>
                        <img src="/images/sub-visual-qna-mo.jpg" alt="" className="mo-only"/>
                </div>
            </div>
        </section>
        <CustomerNavTab></CustomerNavTab>
        <article className="sub-article">
            <div className="sub-article-inner">
                <div className="sub-article-title">
                    <h3 className="title">
                        <span lang="en">CONTACT</span>
                        <span lang="ko">고객문의</span>
                    </h3>
                </div>
                <form>
                    <table className="type1 write1 qna-write-table">
                        <caption></caption>
                        <colgroup>
                            <col/>
                            <col/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th scope="col">이름</th>
                            <td><input type="text"  style={{width:300}} id="name" placeholder="문의하시는 분의 성함을 입력해주세요."></input></td>
                        </tr>
                        <tr>
                            <th scope="col">이메일</th>
                            <td><input type="text" style={{width:300}}  id="email" placeholder="답변 받으실 이메일을 입력해주세요."></input></td>
                        </tr>
                        <tr>
                            <th scope="col" className="vat">내용</th>
                            <td><textarea name="qna-contents" id="qna-contents" cols="30" rows="10" className="textarea01 qna-text"></textarea></td>
                        </tr>
                        <tr>
                            <th scope="col">첨부파일</th>
                            <td>
                                <div className="btn-file" style={{ flex: 1  }}>
                                    <button type="button" className="btn-find" onClick={()=>{document.getElementById('find_file').click();return false;}}>파일선택</button>
                                    <div className="row">
                                        { imgFile != null && fileRender()}
                                    </div>
                                    <div className="row" style={ {whiteSpace: "normal"}} >
                                        {
                                            imgBase64.map((item , index) => {
                                                if(imgBase64[index] == "false") {
                                                   return (
                                                       <div
                                                           style={{
                                                               border: "1px solid",
                                                               margin: 10,
                                                               padding: 10,
                                                               width: 140,
                                                               height: 170 ,
                                                           }}
                                                           key={index}
                                                       ><p style={{textAlign:"center"}}>이미지가 아닙니다.</p></div>
                                                   )
                                                } else {
                                                    return (
                                                        <img
                                                            src={imgBase64[index]}
                                                            alt="이미지"
                                                            style={{
                                                                border: "1px solid",
                                                                margin: 10,
                                                                padding: 10,
                                                                width: 140,
                                                                height: 170
                                                            }}
                                                            key={index}
                                                        />
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="file-ip">
                                        <input type="file" className="up_file" id="find_file" name="files"  multiple={true} onChange={handleChangeFile} /></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="privacy-check">
                        <div className="privacy-check-contents">
                            <p className="title">개인정보 수집 및 이용에 관한 동의</p>
                            <textarea cols="30" rows="10" readOnly={true} defaultValue="
                                1. 개인정보의 수집이용 목적
                                온라인 견적서비스 회신 및 이용자 연결을 위하여 개인정보를 수집합니다.

                                2. 수집하는 개인정보의 항목
                                원활한 고객상담 및 견적서비스를 제공하기 위하여 이름, 이메일, 전화번호, 휴대폰번호, 팩스번호와 같은 개인정보를 수집하고 있습니다.

                                3. 개인정보의 보유 및 이용 기간 수집된 개인정보는 수집 및 이용목적이 달성되면 지체 없이 파기합니다"
                             >
                            </textarea>
                        </div>
                    </div>
                    <div className="row qna-footer-row">
                        <div className="col col-6">
                            <div className="agree-checkbox">
                                <label htmlFor="privacy-agree-check" className="vcheck small medium">
                                    <input type="checkbox" name="privacy-agree-check" id="privacy-agree-check" onClick={ ()=>{ setDisable(!disable)}} />
                                    <i className="ico"></i>
                                    <span className="label-text">개인정보 보호정책에 동의합니다.</span>
                                </label>
                            </div>
                        </div>
                        <div className="col col-6">
                            <div className="board-btn-row board-btn-row-qan">
                                <div className="row">
                                    <div className="col col-12"><Button style={{marginTop: 10}} variant="light" onClick={ ()=>{
                                        ( Object.values(imgFile) != null ||  Object.values(imgFile) != undefined ) ? requestQnA() : requestQnAnotFile()
                                    }} className="vbtn btn-line-black-black large round btn-qan-write" disabled={disable}>문의하기</Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </article>
    </div>

    )
}

export default CustomerQnA;