import {Link , useLocation} from "react-router-dom";


const SubNavRow = () => {
    let location = useLocation();
    let locationCheck = location.pathname.split("/")[2].trim();

    return (
        <div className="sub-nav-row" id="" data-scroll="">
            <nav className="sub-nav">
                <ul className="sub-nav-list">
                    <li className={"sub-nav-item " + (locationCheck === 'companyInfo' ? 'active': '')} ><Link className="text-link" to="/companyIntro/companyInfo">회사 개요</Link></li>
                    <li className={"sub-nav-item " + (locationCheck === 'years' ? 'active': '')} ><Link className="text-link" to="/companyIntro/years">주요 연혁</Link></li>
                    <li className={"sub-nav-item " + (locationCheck === 'organization' ? 'active': '')} ><Link className="text-link" to="/companyIntro/organization">조직도</Link></li>
                    <li className={"sub-nav-item " + (locationCheck === 'waytocome' ? 'active': '')}><Link className="text-link" to="/companyIntro/waytocome">오시는길</Link></li>
                </ul>
            </nav>
        </div>
    );

}

export default SubNavRow;