import {Link, useNavigate, useLocation} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import $ from 'jquery';
import {gsap} from "gsap";
import {history} from "./History";
import {Button} from "react-bootstrap";


const Header = (props) => {
        let location = useLocation();
        const menuRef = useRef(null);
        const [showOption, setShowOption] = useState(props.locationBoolean);
        useOutsideAlerter(menuRef , showOption);
        /**
         *  휴대폰 모드 메뉴바 노출시 다른 곳 클릭하면 메뉴 닫기
         * @param ref
         */
        function useOutsideAlerter(ref , showOption) {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(showOption) {
                        let gnb = $('.gnb-wrap');
                        gnb.fadeOut(100);
                        setShowOption(false);
                    }
                }
            }

            useEffect(() => {
                // Bind the event listener
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            });
        }

        /**
         * css기본
         */
        useEffect( ()=> {
                let gnb = $('.gnb-wrap');
                let gnbOpen = ()=> {
                    let tl = gsap.timeline({paused: true, ease: 'power4.out'});

                    tl.from('.gnb-header, .gnb-footer, .gnb', {x: '100%', duration: 0.15})
                        .from('.btn-gnb-close', {rotation: -180, x: 20, opacity: 0, duration: 0.3, 'transform-origin': 'center'})
                        .from('.gnb-item', {y: 10, opacity: 0, stagger: 0.1, duration: 0.3})

                    gnb.show();
                    tl.play();
                }

                let gnbCloses = ()=> {
                    //document.querySelector(".gnb-wrap").style.display = "none";
                    gnb.fadeOut(100);
                }

                // 메뉴 열기 버튼
                $('.btn-gnb-open').on('click', function() {
                    setShowOption(true);
                    gnbOpen();
                });

                // 메뉴 닫기 버튼
                $('.btn-gnb-close').on('click', function() {
                    setShowOption(false);
                    gnbCloses();
                });

        },[]);
        /**
         * 메뉴바 지우기 관련
         */
        useEffect(()=>{
            let gnb = $('.gnb-wrap');
            if (showOption) {
                gnb.fadeOut(100);
                setShowOption(false);
            }
            return(()=>{
                setShowOption(false);
            })
        }, [location])

        /**
         * 메뉴바 지우기 관련 뒤로가기 앞으로가기 시,
         */
        useEffect(() => {
            const listenBackEvent = () => {
                setShowOption(false);
            };
            const unlistenHistoryEvent = history.listen(({ action }) => {
                if (action === "POP") {
                    listenBackEvent();
                }
            });

            return unlistenHistoryEvent;
        },[]);
        let pathName = location.pathname;
        if( pathName.trim().startsWith("/popup/")){
            return(
                <header className="header">
                    <title>ERSolution</title>
                </header>
            );
        } else {
            return (
                <div className="header-wrap fixed-header">
                    <header className="header">
                        <h1 className="logo"><a href="/"><img src="/images/logo.png"/></a></h1>
                        <button type="button" className="btn-list btn-gnb-open">
                            <em>메뉴 열기</em>
                            <span className="line1"></span>
                            <span className="line2"></span>
                            <span className="line3"></span>
                        </button>

                        <nav className="gnb-wrap">
                            <div className="gnb-header">
                                <div className="gnb-logo"><img src="/images/logo-black.png" alt="ERsolution"/></div>

                                <button type="button" className="btn-x btn-gnb-close">
                                    <em>메뉴 닫기</em>
                                    <span className="line1"></span>
                                    <span className="line2"></span>
                                </button>
                            </div>

                            <ul className="gnb" ref={menuRef}>
                                <li className="gnb-item"><Link className="text-link" to="/companyIntro/companyInfo">회사
                                    소개</Link>
                                    <ul className="gnb-sub-list">
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/companyIntro/companyInfo">회사 개요</Link>
                                        </li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/companyIntro/years">주요 연혁</Link></li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/companyIntro/organization">조직도</Link>
                                        </li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/companyIntro/waytocome">오시는길</Link></li>
                                    </ul>
                                </li>
                                <li className="gnb-item"><Link className="text-link" to="/businessIntro/vehiclecontrol">사업
                                    소개</Link>
                                    <ul className="gnb-sub-list">
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/businessIntro/vehiclecontrol">차량관제솔루션</Link>
                                        </li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/businessIntro/mobileplatform">모바일
                                            플랫폼</Link></li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/businessIntro/agriculturalProductsDistribution">농수산유통솔루션</Link>
                                        </li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/businessIntro/siIntro">SI</Link></li>
                                    </ul>
                                </li>
                                <li className="gnb-item"><Link className="text-link" to="/customer/notice">고객센터</Link>
                                    <ul className="gnb-sub-list">
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/customer/notice">공지사항</Link></li>
                                        <li className="gnb-sub-item"><Link className="text-link"
                                                                           to="/customer/qna">고객문의</Link></li>
                                    </ul>
                                </li>
                            </ul>

                            <div className="gnb-footer">
                                <ul className="gnb-footer-nav">
                                    <li><a href="#">사이트맵</a></li>
                                    <li><Link className="text-link" to="/companyIntro/waytocome">오시는길</Link></li>
                                    <li><a href="mailto:help@erst.kr">이메일 help@erst.kr</a></li>
                                </ul>
                            </div>
                        </nav>

                        <Link to="/customer/notice"
                              className="vbtn btn-line-white-white btn-header-contact text-link">CONTACT</Link>
                    </header>
                </div>
            );
        }
}

export default Header;