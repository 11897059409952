import axios from "axios";
import {useEffect , useState} from "react";
import {Badge, Button} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import {gsap} from "gsap";
import CustomerNavTab from "./Component/CustomerNavTab";

const Notice = () => {

    // 맥스 페이지
    let [numPages , setNumPages] = useState(1);
    let [posts, setPosts] = useState([]);
    let [page, setPage] = useState(1);
    let [total , setTotal] = useState(5);
    let [pageNumber , setPageNumber] = useState([]);
    let [category , setCategory] = useState("subject");
    let [keyword , setKeyword] = useState();
    let [search , setSearch] = useState(false);
    let navigate = useNavigate();

    const getList = async () => {
        // Promise.all([ ])
        Promise.all([
            axios.get(process.env.REACT_APP_REST_URL+'/user/notice/list',
                {
                    params: {
                        page: page,
                        category: category,
                        keyword: keyword
                    }
                }
            )
        ]).then((data) => {
            setPosts(data[0].data.response);
            setTotal(data[0].data.total);
            setNumPages(Number(data[0].data.message));
            let arr = [];
            for (let i = ( page % 10 ) === 0 ? (( Math.floor(Number(  page/10 )) * 10 ) - 9 ) : ( Math.floor(Number(  page/10 )) * 10 ) + 1 ; i <= ( (( page % 10 ) === 0 ? (( Math.floor(Number(  page/10 )) * 10 ) - 9 ) : ( Math.floor(Number(  page/10 )) * 10 ) + 1) + 9 ) ; i++) {
                if(i <= (data[0].data.total/10) + 1) {
                    arr.push(i);
                }
            }

            let copy = [...arr];
            setPageNumber(copy)

        }).catch(function (error) {

        })

    }


    useEffect(() => {

        let topImg = $('.sub-visual-img img');
        let tl = gsap.timeline({paused: true});

        tl.to(topImg, {
            'transform-origin': 'center',
            'scale': 1,
            'duration': 2,
            'ease': "expoScale(0.1, 8)"
        }, 'same').to('.sub-visual-title span', {
            opacity: 1,
            y: 0,
            stagger: 0.2,
        }, '-=1.5')

        tl.play();
        // topImgs();
        getList();
    } ,[page])


    useEffect(() => {
        setPage(1);
        getList();
    } ,[search])

    return (
        <div className="subpage-container">

            <section className="sub-visual-row">
                <article className="sub-visual-article">
                    <ul className="breadcrumbs">
                        <li><a href="/">HOME</a></li>
                        <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                        <li>고객센터</li>
                        <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                        <li className="current">공지사항</li>
                    </ul>

                    <div className="sub-visual-title">
                        <h2 className="title">
                            <span lang="en">ERSOLUTION</span>
                            <span lang="ko">고객센터</span>
                        </h2>
                    </div>
                </article>
                <div className="sub-visual-img">
                    <div className="inner">
                        <img src="/images/sub-visual-notice-pc.jpg" alt="" className="pc-only"/>
                            <img src="/images/sub-visual-notice-mo.jpg" alt="" className="mo-only"/>
                    </div>
                </div>
            </section>
            <CustomerNavTab></CustomerNavTab>
            <article className="sub-article">
                <div className="sub-article-inner">

                    <div className="sub-article-title">
                        <h3 className="title">
                            <span lang="en">NOTICE</span>
                            <span lang="ko">공지사항</span>
                        </h3>
                        <p className="text">이알솔루션에서 알리는 새로운 소식 입니다</p>
                    </div>
                    <div className="table-top-row">
                        <div className="table-top-search">
                            <p className="counting">총 <span className="num">{total}</span>개</p>

                            <div className="table-search-form">
                                <div className="col-select">
                                    <label htmlFor="table-search-select">
                                        <select id="table-search-select" name="table-search-select"
                                                className="select01" defaultValue="subject" onChange={ (e) => {setCategory(e.target.value)}}>
                                            <option value="subject">제목</option>
                                            <option value="content">내용</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="col-input-text">
                                    <label htmlFor="table-search-keyword">
                                        <input type="text" name="table-search-keyword" id="table-search-keyword"
                                               className="input01 input-keyword" placeholder="검색어 입력" onKeyUp={ (e) => setKeyword(e.target.value)}/>
                                    </label>
                                    <button onClick={ ()=> {  setSearch(!search) } } type="button" className="vbtn btn-table-search-submit"><span
                                        className="label" >검색</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table className="type1 list1 notice-table-list">
                        <caption></caption>
                        <colgroup>
                            <col className="col-num"/>
                                <col className="col-title"/>
                                    <col className="col-date"/>
                        </colgroup>
                        <tbody>

                                {
                                    posts.map(function (a , i) {
                                        let id = posts[i].noticeNumber;
                                        return (
                                            <tr key={i}>
                                                {posts[i].noticeHigh == 0 ? <td className="num">  {posts[i].pageNumbers} </td> : <td className=""> <Badge bg={posts[i].noticeHigh == 1 ? "secondary" : ""}> 공지사항 </Badge></td> }

                                                <td className="title"><a onClick={ ()=> {navigate("/customer/notice/detail/"+id)}} className="table-title-link">{posts[i].subject}</a></td>
                                                <td className="date">{posts[i].rDate}</td>
                                            </tr>
                                        )
                                    })
                                }

                        </tbody>
                    </table>

                    <div className="pc-only">

                        <div className="pagination" >
                            <a onClick={() => setPage( 1) }  style={ page === 1 ? {display : "none"} : {display : "inline-block"}} title="처음" className="btn-arrow first"><span className="blind">처음</span><i
                                className="ri-arrow-left-s-line"></i><i className="ri-arrow-left-s-line"></i></a>
                            <a onClick={() => setPage(page - 1)} style={ page === 1 ? {display : "none"} : {display : "inline-block"}} title="이전" className="btn-arrow prev"><span className="blind">이전</span><i
                                className="ri-arrow-left-s-line"></i></a>
                            {
                                    pageNumber.map(function ( a , i) {
                                        return (
                                                <a
                                                    key={a}
                                                    onClick={() => setPage(a)}
                                                    className={page === a ? "active" : ""}
                                                >
                                                    {a}
                                                </a>

                                        )
                                    })
                            }
                            <a onClick={() => setPage(page + 1)} style={ page === numPages + 1? {display : "none"} : {display : "inline-block"}} title="다음" className="btn-arrow next"><span className="blind">다음</span><i
                                className="ri-arrow-right-s-line"></i></a>
                            <a onClick={() => setPage( Number(Math.floor(total/10) ) + 1)} style={ page === numPages  + 1 ? {display : "none"} : {display : "inline-block"}}  title="끝" className="btn-arrow end"><span className="blind">끝</span><i
                                className="ri-arrow-right-s-line"></i><i className="ri-arrow-right-s-line"></i></a>
                        </div>

                    </div>


                    <div className="mo-only">
                        <div className="board-page-move-buttons">
                            <button type="button" className="vbtn btn-page-move prev" title="이전 페이지"
                                    aria-label="이전 페이지"><i className="ri-arrow-left-s-line"></i></button>
                            <button type="button" className="vbtn btn-page-move next" title="다음 페이지"
                                    aria-label="다음 페이지"><i className="ri-arrow-right-s-line"></i></button>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default Notice;