import {useEffect} from "react";
import $ from "jquery";
import {gsap} from "gsap";
import {useOutletContext} from "react-router-dom";
import SubVisualArticle from "./SmallComponent/SubVisualArticle";
import SubNavRow from "./SmallComponent/SubNavRow";
import SubArticleTitle from "./SmallComponent/SubArticleTitle";

const Years = () => {

    const { data } = useOutletContext();

    return (
        <>
            <SubVisualArticle name={data.name} imagePC={data.imageNamePC} imageMO={data.imageNameMO}></SubVisualArticle>
            <SubNavRow></SubNavRow>
            <article className="sub-article">
                <div className="sub-article-inner">
                    <SubArticleTitle enName={data.enName} koName={data.koName}></SubArticleTitle>
                    <div className="company-history-wrap">
                        <ul className="history-list">
                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2015</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">07월 이알솔루션 설립</li>
                                            <li className="history-content-item">07월 창업보육센터 입주</li>
                                            <li className="history-content-item">10월 기업부설연구소 설립</li>
                                            <li className="history-content-item">12월 벤처기업 인증</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>
                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2016</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">2월 중소기업 진흥공단 정책자금 선정</li>
                                            <li className="history-content-item">11월 창업맞춤형사업 (정부지원사업) 선정</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>

                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2017</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">12월 모바일제작플랫폼 TWOAPP 서비스 런칭</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>
                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2018</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">4월 본점 인천 부평 → 안양 평촌으로 이전</li>
                                            <li className="history-content-item">12월 자본금 증자 19,700만원</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>

                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2019</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">8월 농산물유통 솔루션 따옴농장 MOU 체결</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>

                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2020</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">5월 차량정보 서비스 관제 포털 CHACADA 런칭</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>

                            <li className="history-item wow fadeInUp">
                                <dl className="history-year-row">
                                    <dt className="year">2021</dt>
                                    <dd className="history">
                                        <ul className="history-content">
                                            <li className="history-content-item">7월 교통안전공단 경제운전 시스템 개발 수주</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </li>

                        </ul>
                    </div>
                </div>
            </article>
        </>
    )
}

export default Years;