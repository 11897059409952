let EtcData = [
    {
        name: "모바일 쇼핑몰 앱"
    },

    {
        name: "마케팅 지원앱"
    },

    {
        name: "산행정보 및 산행일정 지원 앱"
    },

    {
        name: "쿠폰을 이용한 O2O서비스 지원 앱"
    },

    {
        name: "학술연구를 위한 설문조사 앱"
    },

    {
        name: "가상번호를 활용한 주차관리 앱"
    },

]


export default EtcData;