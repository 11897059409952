
let ArticleText =(props) => {
    // return (
    //         props.subArticleData.data.subArticleData[0].content2 != null ?
    //             ((props.subArticleData.data.subArticleData[0].id == 'farmda') ? <p className="text wow fadeInUp"> {props.subArticleData.data.subArticleData[0].content1 != undefined && props.subArticleData.data.subArticleData[0].content1} <br/> {props.subArticleData.data.subArticleData[0].content2} </p> : <p className="text">  {props.subArticleData.data.subArticleData[0].content1 != undefined && props.subArticleData.data.subArticleData[0].content1 }<br/>{ props.subArticleData.data.subArticleData[0].content2}</p> )
    //             : ((props.subArticleData.data.subArticleData[0].id == 'farmda') ? <p className="text wow fadeInUp"> {props.subArticleData.data.subArticleData[0].content1 != undefined && props.subArticleData.data.subArticleData[0].content1}</p> : <p className="text"> {props.subArticleData.data.subArticleData[0].content1 != undefined && props.subArticleData.data.subArticleData[0].content1}</p> )
    // )

    return (
        props.subArticleData.data.subArticleData[0].content2 != null ?
            <p className="text wow fadeInUp"> {props.subArticleData.data.subArticleData[0].content1 != undefined && props.subArticleData.data.subArticleData[0].content1} <br/> {props.subArticleData.data.subArticleData[0].content2} </p>
            :  <p className="text wow fadeInUp"> {props.subArticleData.data.subArticleData[0].content1 != undefined && props.subArticleData.data.subArticleData[0].content1}</p>
    )
}


export default ArticleText;