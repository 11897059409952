import SiAppBoardListData from "../Data/SiAppBoardListData";
import {useEffect, useState} from "react";
import SiAppBoardList from "./SmallComponents/SiAppBoardList";
import SiWebBoardListData from "../Data/SiWebBoardListData";
import SiWebBoardList from "./SmallComponents/SiWebBoardList";
import SubVisualRow from "./SmallComponents/SubVisualRow";
import SubNavBusiness from "./SmallComponents/SubNavBusiness";
import {useOutletContext} from "react-router-dom";


const Si = ()=> {

    let [ appLimit ,setAppLimit] = useState(10);
    let [ webLimit , setWebLimit ] = useState( 4 );
    let [ siAppBoardListData , setSiAppBoardListData ] = useState( SiAppBoardListData.slice( 0 , appLimit) );
    let [ siWebBoardListData , setSiWebBoardListData ] = useState( SiWebBoardListData.slice( 0 , webLimit) );

    const appListSize =  SiAppBoardListData.length;
    const webListSize = SiWebBoardListData.length;

    const { data } = useOutletContext();

    useEffect(()=>{
        setSiAppBoardListData( SiAppBoardListData.slice(0 , appLimit))
    } , [appLimit])

    useEffect(()=>{
        setSiWebBoardListData( SiWebBoardListData.slice( 0 , webLimit))
    } , [webLimit])

    return (
        <>
            <SubVisualRow current={data.current}></SubVisualRow>
            <SubNavBusiness></SubNavBusiness>
            <article className="sub-article">
                <div className="sub-article-inner">
                    <div className="sub-article-title type2" id="sub-article-title-si">
                        <h3 className="title wow fadeInUp">
                            <span lang="en">SYSTEM INTEGRATOR</span>
                            <span lang="ko">SI</span>
                        </h3>
                        <p className="text wow fadeInUp">고객 인프라에 대해 체계적 구축, 관리 통합운영을 통해 고객 비지니스 향상에 기여합니다. 민간/공공 분야에서 다수
                            레퍼런스를 보유하고 있으며, 앱 개발, 키오스크 개발, 웹 프로그램등 IT서비스를 제공하고 있습니다.</p>
                    </div>


                    <div className="chacarda-img wow fadeInUp">
                        <img src="/images/img-si.jpg" alt="" className="pc-only"/>
                            <img src="/images/img-si-mo.jpg" alt="" className="mo-only"/>
                    </div>

                    <div className="app-list-wrap">
                        <div className="sub-article-title2">
                            <h4 className="title wow fadeInUp">APP</h4>
                        </div>

                        <div className="board-list-type1">
                            <ul className="board-list">
                             <SiAppBoardList siAppBoardListData={siAppBoardListData} limit={appLimit}></SiAppBoardList>
                            </ul>
                        </div>

                        <div className="board-btn-row wow fadeInUp">
                            <div className="row">
                                <div className="col col-12">
                                    <button type="button"
                                            className="vbtn btn-line-black-black large round btn-board-more" style={ appLimit >= appListSize ?  {display:"none" , margin:"auto"} : { display:"block", margin:"auto"}} onClick={()=>{ setAppLimit((appLimit + 4) <= appListSize ? appLimit + 4 : appListSize )}}>더보기
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="web-list-wrap">
                        <div className="sub-article-title2 wow fadeInUp">
                            <h4 className="title">WEB</h4>
                        </div>

                        <SiWebBoardList siWebBoardListData={siWebBoardListData}></SiWebBoardList>


                        <div className="board-btn-row wow fadeInUp">
                            <div className="row">
                                <div className="col col-12">
                                    <button type="button"
                                            className="vbtn btn-line-black-black large round btn-board-more"  style={ webLimit >= webListSize ?  {display:"none", margin:"auto"} : { display:"block", margin:"auto"}} onClick={()=>{ setWebLimit((webLimit + 4) <= webListSize ? webLimit + 4 : webListSize )}}>더보기
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </article>
        </>

    )
}

export default Si;
