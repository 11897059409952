
const ArticleTitle = (props)=> {
        return (
            <h3 className='title wow fadeInUp'>
                <span lang="en">{props.subArticleData.data.subArticleData[0].EnName}</span>
                {props.subArticleData.data.subArticleData[0].KoName2 != null ?
                    <span lang="ko">{props.subArticleData.data.subArticleData[0].KoName1}
                        <br/> {props.subArticleData.data.subArticleData[0].KoName2} </span>
                    : <span lang="ko">{props.subArticleData.data.subArticleData[0].KoName1}</span>}
            </h3>
        )
}

export default ArticleTitle;
