import {MainSection} from "./MainSection";
import {NoticeSection} from "./NoticeSection";
import {MainBannerSection} from "./MainBannerSection";
import {MainPRSection} from "./MainPRSection";
import {MainContactSection} from "./MainContactSection";
import {MainMapSection} from "./MainMapSection";
import {gsap} from "gsap";
import {useEffect, useRef} from "react";
import {useState} from "react";
import {createPortal} from "react-dom";
import axios from "axios";
import Modal from "./Popup/Modal";
import {useNavigate} from "react-router-dom";



const popupRestUrl = process.env.REACT_APP_REST_URL + "/user/popup/findByPopup";

const RenderInWindow = (props) => {
    const [container, setContainer] = useState(null);
    const newWindow = useRef(window);
    useEffect(() => {
        const div = document.createElement("div");
        setContainer(div);
    }, []);

    useEffect(() => {
        if (container) {
            let options;
            let idxCount = 0;
            Promise.all([axios.get( popupRestUrl )]).then( (data) =>{
                let popupList = data[0].data.response;
                let array = [];
                popupList.map(function (item , i) {
                    let kind = item.popupKind;
                    let used = item.useCheck;
                    let checkScroll = item.scrollCheck == 'Y' ? 'yes' : 'no';
                    if(used == "Y") {
                        if (kind == "normal") {
                            let windowName = 'windowPopup'+ item.popupNumber + "=";
                            // props.setPopupHtml(item.content);
                            options = `' ,fullscreen=no , titlebar = no , menubar=no , resizable=no , toolbar=no ,top=` + item.positionY + `,left=` + item.positionX + `,width=` + item.widths +
                                `,height=` + item.heights + `,scrollbars=` + checkScroll + `'`;

                            if (document.cookie.split(';').some((item) => item.trim().startsWith(windowName))) {
                            } else {
                                window.open("/popup/" + item.popupNumber, "_blank" , options);
                            }
                            // } else {
                            //
                            //     newWindow.current = window.open(
                            //         "",
                            //         "",
                            //         options
                            //     );
                            //     let title = document.createElement("title");
                            //     title.innerHTML = item.subject;
                            //
                            //     let dayCloseButton = document.createElement("button");
                            //     dayCloseButton.style = "width:100px; height:30px";
                            //     dayCloseButton.innerHTML = "오늘 하루 닫기";
                            //     dayCloseButton.id = "test";
                            //     let script = document.createElement("script");
                            //     script.innerHTML = "let closeButton = document.querySelector('#test');" +
                            //         "closeButton.addEventListener('click' , function(){" +
                            //         "document.cookie = 'windowPopup" + item.popupNumber + "=value; path=/;';" +
                            //         "window.close();" +
                            //         "})" +
                            //         ""
                            //     newWindow.current.document.head.appendChild(title);
                            //     newWindow.current.document.body.appendChild(container);
                            //     newWindow.current.document.body.appendChild(dayCloseButton);
                            //     newWindow.current.document.body.appendChild(script);
                            //     const curWindow = newWindow.current;
                            //     return () => curWindow.close();
                            // }

                        } else {
                            let modalObject = {
                                top: item.positionY,
                                left:  item.positionX,
                                width: item.widths,
                                height: item.heights,
                                content: item.content,
                                idx: idxCount
                            };
                            array.push(modalObject);
                            idxCount += 1;
                        }
                    }
                })
                if(array.length != 0) {
                    props.setModalContent(
                        array
                    )
                    return;
                }
            })

        }
    }, [container]);
    return container && createPortal(props.children, container);
};



const Main = () => {
    const [popupHtml , setPopupHtml] = useState(null);

    useEffect(()=>{
        /**
         *  뒤로 가기 할 때 퍼블 적용 안되는 현상 수정
         */

        let visualimg = '.main-visual-img img';

        let tl = gsap.timeline({paused: true});

        tl.to(visualimg, {
            'transform-origin': 'center',
            'scale': 1,
            'duration': 2,
            'ease': "expoScale(0.1, 8)"
        }, 'same')
            .to('.copy-box p', {
                opacity: 1,
                y: 0,
                stagger: 0.2,
            }, "-=1.5")

        tl.play();

    }, [])
    const [modalContent , setModalContent] = useState([] );
    const [closeCheck , setCloseCheck] = useState(true);

    const [modalVisible, setModalVisible] = useState([]);
    const closeModal = (e) => {

        let idxObject = {
            idx: e.target.dataset.idx,
            booleanCheck: false
        };

        let copy = [...modalVisible];
        copy.push(idxObject)
        setModalVisible(copy);
    }

    return (
        <>
            <RenderInWindow setPopupHtml={setPopupHtml} setModalContent={setModalContent}><div dangerouslySetInnerHTML={{ __html: popupHtml }}/></RenderInWindow>
            <Modal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal} modalContent={modalContent} setModalVisible={setModalVisible} closeCheck={closeCheck} setCloseCheck={setCloseCheck}></Modal>
            <MainSection></MainSection>
            <NoticeSection></NoticeSection>
            <MainBannerSection></MainBannerSection>
            <MainPRSection></MainPRSection>
            <MainContactSection></MainContactSection>
            <MainMapSection></MainMapSection>
        </>
    );
}
export default Main;


//import {MainSection} from "./MainSection";
// import {NoticeSection} from "./NoticeSection";
// import {MainBannerSection} from "./MainBannerSection";
// import {MainPRSection} from "./MainPRSection";
// import {MainContactSection} from "./MainContactSection";
// import {MainMapSection} from "./MainMapSection";
// import {gsap} from "gsap";
// import {useEffect, useRef} from "react";
// import {useState} from "react";
// import {createPortal} from "react-dom";
// import axios from "axios";
// import Modal from "./Popup/Modal";
//
//
//
// const popupRestUrl = process.env.REACT_APP_REST_URL + "/user/popup/findByPopup";
//
// const RenderInWindow = (props) => {
//     const [container, setContainer] = useState(null);
//     const newWindow = useRef(window);
//
//     useEffect(() => {
//         const div = document.createElement("div");
//         setContainer(div);
//     }, []);
//
//     useEffect(() => {
//         if (container) {
//             let options;
//             Promise.all([axios.get( popupRestUrl )]).then( (data) =>{
//                 let popupList = data[0].data.response;
//                 let array = [];
//                 popupList.map(function (item , i) {
//                     let kind = item.popupKind;
//                     let used = item.useCheck;
//
//                     let checkScroll = item.scrollCheck == 'Y' ? 'yes' : 'no';
//                     if(used == "Y") {
//                         if (kind == "normal") {
//                             props.setPopupHtml(item.content);
//                             options = `top=` + item.positionY + `,left=` + item.positionX + `,width=` + item.widths +
//                                 `,height=` + item.heights + `,scrollbars=` + checkScroll ;
//
//                             newWindow.current = window.open(
//                                 "",
//                                 "",
//                                 options
//                             );
//                             let title = document.createElement("title");
//                             title.innerHTML=item.subject;
//                             newWindow.current.document.head.appendChild(title);
//                             newWindow.current.document.body.appendChild(container);
//                             const curWindow = newWindow.current;
//                             return () => curWindow.close();
//                         } else {
//                             // props.setModalContent({
//                             //     top: item.positionY,
//                             //     left:  item.positionX,
//                             //     width: item.widths,
//                             //     height: item.heights,
//                             //     content: item.content
//                             // });
//                             let modalObject = {
//                                 top: item.positionY,
//                                 left:  item.positionX,
//                                 width: item.widths,
//                                 height: item.heights,
//                                 content: item.content
//                             };
//                             array.push(modalObject);
//                             props.setModalContent(
//                                 array
//                             )
//
//                         }
//                     }
//                 })
//
//             })
//
//         }
//     }, [container]);
//
//     return container && createPortal(props.children, container);
// };
//
//
// const Main = () => {
//
//     const [popupHtml , setPopupHtml] = useState(null);
//
//     useEffect(()=>{
//         /**
//          *  뒤로 가기 할 때 퍼블 적용 안되는 현상 수정
//          */
//
//         let visualimg = '.main-visual-img img';
//
//         let tl = gsap.timeline({paused: true});
//
//         tl.to(visualimg, {
//             'transform-origin': 'center',
//             'scale': 1,
//             'duration': 2,
//             'ease': "expoScale(0.1, 8)"
//         }, 'same')
//             .to('.copy-box p', {
//                 opacity: 1,
//                 y: 0,
//                 stagger: 0.2,
//             }, "-=1.5")
//
//         tl.play();
//     }, [])
//     const [modalContent , setModalContent] = useState([] );
//     const [modalVisible, setModalVisible] = useState([]);
//
//
//     const closeModal = () => {
//         let idxArray = [];
//         for(let i = 0; i < modalContent.length; i++){
//             let idxObject = {
//                 idx: i,
//                 booleanCheck: false
//             };
//             idxArray.push(idxObject);
//         }
//
//         setModalVisible(idxArray);
//     }
//
//
//     return (
//         <>
//             <RenderInWindow setPopupHtml={setPopupHtml} setModalContent={setModalContent}><div dangerouslySetInnerHTML={{ __html: popupHtml }}/></RenderInWindow>
//             <Modal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal} modalContent={modalContent}></Modal>
//             <MainSection></MainSection>
//             <NoticeSection></NoticeSection>
//             <MainBannerSection></MainBannerSection>
//             <MainPRSection></MainPRSection>
//             <MainContactSection></MainContactSection>
//             <MainMapSection></MainMapSection>
//         </>
//     );
// }

