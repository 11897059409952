import SubVisualArticle from "./SmallComponent/SubVisualArticle";
import SubNavRow from "./SmallComponent/SubNavRow";
import SubArticleTitle from "./SmallComponent/SubArticleTitle";
import {useOutletContext} from "react-router-dom";

const CompanyInfo = () => {

    const { data } = useOutletContext();

    return (

        <>
            <SubVisualArticle name={data.name} imagePC={data.imageNamePC} imageMO={data.imageNameMO}></SubVisualArticle>
            <SubNavRow></SubNavRow>
            <article className="sub-article">
                <div className="sub-article-inner">
                    <SubArticleTitle enName={data.enName} koName={data.koName}></SubArticleTitle>
                    <div className="company-overview-wrap">
                        <dl className="company-overview-textbox wow fadeInUp">
                            <dt className="company-overview-textbox-label"><span className="text">ABOUT US</span></dt>
                            <dd className="company-overview-textbox-title">
                                이알솔루션은 IT기술을 기반으로<br/> <em>지속적인 혁신</em>을 통해<br className="mo-only"/> <em>고객을 만족</em>시키는 기업
                                입니다
                            </dd>
                        </dl>

                        <dl className="company-overview-textbox wow fadeInUp">
                            <dt className="company-overview-textbox-label"><span className="text">Greeting</span></dt>
                            <dd className="company-overview-textbox-text">
                                이알솔루션은 차량관제, 모바일플랫폼, 농수산유통 솔루션 및 IT 서비스/시스템 구축 전문 IT 기반 업체로서 빅데이터, AI 등 신성장동력에 사업을 박차고
                                있는 기업입니다. <br/><br/>

                                IT는 우리의 생활에 깊숙히 다가와 있습니다. 손안의 PC 스마트폰을 기반으로 한 기술과 WEB 제작기술, CS프로그램 개발을 필두로, 다수의 IT 서비스 및
                                시스템 개발 경험이 있습니다. <br/><br/>

                                차량관제솔루션 CHACARDA, 모바일플랫폼 TWOAPP, 농수산유통솔루션 FARMDA 런칭하여 판매 및 유지보수 고도화(업데이트) 작업을 정기/비정기적으로
                                하고 있으며, 솔루션/플랫폼의 활성화 하고 있으며, 관련 빅데이터 AI등 신성장 동력 기술 기반 강화해 나가고 있습니다. <br/><br/>

                                변화를 두려워하면 혁신은 없습니다. <br/>IT기업은 항상 변화하는것에 대응할 준비를 해야하며, 저희는 항상 준비하고 있습니다. <br/><br/>

                                제품을 개발, 판매하는 단순한 영업 행위 보다는 고객의 입장에서 효율적인 업무프로세스, 효율화, 이익증대에 기여한다는 사명감으로 고객의 IT Infra와
                                전자상거래 구축을 위한 토탈 솔루션을 제공하여 성공적인 비즈니스 파트너로서의 소임을 다하겠습니다.

                                <div className="ceo-sign">
                                    <dl>
                                        <dt>㈜이알솔루션</dt>
                                        <dd>대표이사 이길용</dd>
                                    </dl>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </article>
        </>

    );
}

export default CompanyInfo;