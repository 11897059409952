import {useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {setCookie} from "../../../Components/Cookies";


const popupRestUrl = process.env.REACT_APP_REST_URL + "/user/popup/findByPopup/";



let WindowPopup = () =>{
    let {popupNumber} = useParams();
    let [content , setContent] = useState();
    useEffect(()=> {
        let Promise = axios.get(popupRestUrl + popupNumber);
        Promise.then((data) => {
            setContent(data.data.response.content);
            }
        )
    },[])
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: content}}>
            </div>
            <div className="row-cols-2" style={{backgroundColor:"grey", bottom: 1}}>
                <Button className="btn btn-secondary" style={ {backgroundColor: "black"}} onClick={()=> {DayClosed(popupNumber)}}>오늘 하루 닫기</Button>
                <Button className="btn btn-secondary" style={ {backgroundColor: "black"}}  onClick={()=> {Closed()}}>닫기</Button>
            </div>
        </>
    );
}

let DayClosed = (popupNumber) =>{
    // document.cookie = "windowPopup" + popupNumber + "=value; path=/;" ;
    // window.close();
    const expiry = new Date()
    expiry.setMinutes( expiry.getMinutes() + 12800);
    let cookieName = 'windowPopup' + popupNumber;
    setCookie(cookieName , expiry.getDate() , {path: "/" , expires: expiry});
    window.close();
}

let Closed = ()=>{
    window.close();
}

export default WindowPopup;