

const SubArticleTitle = (props)=> {
    return (
        <div className="sub-article-title">
            <h3 className="title wow fadeInUp">
                <span lang="en">{props.enName}</span>
                <span lang="ko">{props.koName}</span>
            </h3>
        </div>
    )
}

export default SubArticleTitle;