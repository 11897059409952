
import dongwon from "../../../images/app/img_app1.png";
import mark from "../../../images/app/img_app2.png";
import masung from "../../../images/app/img_app3.png";
import noriter from "../../../images/app/img_app4.png";
import jeegy from "../../../images/app/img_app5.png";
import mozoolet from "../../../images/app/img_app6.png";
import transQ from "../../../images/app/img_app7.png";
import ddaum from "../../../images/app/img_app8.png";
import MOVIA from "../../../images/app/img_app9.png";
import pet119 from "../../../images/app/img_app10.png";
import sence from "../../../images/app/img_app11.png";
import smart from "../../../images/app/img_app12.png";
let SiAppBoardListData = [
    {
        id: "dongwon",
        title: "프로그램 개발",
        descText: "경제운전 체험교육 시스템",
        image: dongwon

    },

    {
        id: "mark",
        title: "앱 제작",
        descText: "머크 MBTI",
        image: mark

    },

    {
        id: "masung",
        title: "앱 제작",
        descText: "마성의 토닥토닥",
        image: masung

    },

    {
        id: "noriter",
        title: "앱 제작",
        descText: "노보노디스크 NORITER",
        image: noriter
    },

    {
        id: "jeegy",
        title: "앱 제작",
        descText: "JEEGY",
        image: jeegy
    },

    {
        id: "mozoolet",
        title: "앱 제작",
        descText: "Mozoolet",
        image: mozoolet
    },
    {
        id: "transQ",
        title: "앱 제작",
        descText: "Trans Q",
        image: transQ
    },

    {
        id: "ddaum",
        title: "앱 제작",
        descText: "따옴농장",
        image: ddaum
    },

    {
        id: "movia",
        title: "앱 제작",
        descText: "MOVIA",
        image: MOVIA
    },

    {
        id: "pet119",
        title: "앱 제작",
        descText: "PET 119",
        image: pet119
    },
    {
        id: "sence",
        title: "앱 제작",
        descText: "센스2",
        image: sence
    },

    {
        id: "smart",
        title: "앱 제작",
        descText: "SMART FLAT",
        image: smart
    },



]




export default SiAppBoardListData;










