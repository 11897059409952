

const SiWebBoardList = (props)=> {
    return (
        <div className="board-list-type1" data-dimed="true" data-thumb-animation="true">
            <ul className="board-list">
                {

                    props.siWebBoardListData.map(function (a, i){
                            return (
                                <li className="board-item wow fadeInUp" key={i}>
                                    <a href="#" className="board-link">
                                        <div className="board-desc">
                                            <dl>
                                                <dt className="board-desc-title">{props.siWebBoardListData[i].title}</dt>
                                                <dd className="board-desc-text">{props.siWebBoardListData[i].text}</dd>
                                            </dl>
                                        </div>
                                        <div className="board-img"><img src={props.siWebBoardListData[i].image}
                                                                        alt=""/>
                                        </div>
                                    </a>
                                </li>
                            )
                     }
                    )

                }
            </ul>
        </div>
    )
}

export default SiWebBoardList;