import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import $ from "jquery";
import {gsap} from "gsap";
import CustomerNavTab from "./Component/CustomerNavTab";

const NoticeDetail = ()=> {

    let navigate = useNavigate();
    let {id} = useParams();
    let [noticeSubject , setNoticeSubject] = useState("");
    let [noticeContent , setNoticeContent] = useState("");
    let [noticerDate , setNoticerDate] = useState("");
    let [noticeNumber ,setNoticeNumber] = useState(id);

    let [beforeNoticeSubject , setBeforeNoticeSubject] = useState("");
    let [nextNoticeSubject , setNextNoticeSubject] = useState("");

    let [nextNoticeRDate , setNextNoticeRDate] = useState("");
    let [nextNoticeNumber , setNextNoticeNumber] = useState("");

    let [beforeNoticeRDate , setBeforeNoticeRDate] = useState("");
    let [beforeNoticeNumber , setBeforeNoticeNumber] = useState("");

    const getDetail = async ()=>{
        Promise.all([
            axios.get(
                process.env.REACT_APP_REST_URL+"/user/notice/findByNotice/" + id
            )
            ]).then( (data) => {
                    setNoticerDate(data[0].data.response.rdate)
                    setNoticeSubject(data[0].data.response.subject)
                    setNoticeContent(data[0].data.response.content)
                    setBeforeNoticeSubject(data[0].data.response.beforeSubject)
                    setNextNoticeSubject(data[0].data.response.nextSubject)
                    setBeforeNoticeNumber(data[0].data.response.beforeNoticeNumber)
                    setNextNoticeNumber(data[0].data.response.nextNoticeNumber)
                    setBeforeNoticeRDate(data[0].data.response.beforeRDate)
                    setNextNoticeRDate(data[0].data.response.nextRDate)
                }
            )



    }

    useEffect( ()=> {

        let topImg = $('.sub-visual-img img');
        let tl = gsap.timeline({paused: true});

        tl.to(topImg, {
            'transform-origin': 'center',
            'scale': 1,
            'duration': 2,
            'ease': "expoScale(0.1, 8)"
        }, 'same').to('.sub-visual-title span', {
            opacity: 1,
            y: 0,
            stagger: 0.2,
        }, '-=1.5')

        tl.play();

        getDetail();
    } , [noticeNumber])

    
    return (
        <div className="subpage-container">
            <section className="sub-visual-row">
                <article className="sub-visual-article">
                    <ul className="breadcrumbs">
                        <li><a href="/">HOME</a></li>
                        <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                        <li>고객센터</li>
                        <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                        <li className="current">공지사항</li>
                    </ul>

                    <div className="sub-visual-title">
                        <h2 className="title">
                            <span lang="en">ERSOLUTION</span>
                            <span lang="ko">고객센터</span>
                        </h2>
                    </div>
                </article>

                <div className="sub-visual-img">
                    <div className="inner">
                        <img src="/images/sub-visual-notice-pc.jpg" alt="" className="pc-only"/>
                        <img src="/images/sub-visual-notice-mo.jpg" alt="" className="mo-only"/>
                    </div>
                </div>
            </section>
            <CustomerNavTab></CustomerNavTab>
            <article className="sub-article">
                <div className="sub-article-inner">

                    <div className="sub-article-title">
                        <h3 className="title">
                            <span lang="en">NOTICE</span>
                            <span lang="ko">공지사항</span>
                        </h3>
                        <p className="text">이알솔루션에서 알리는 새로운 소식 입니다</p>
                    </div>
                    <div className="board-detail-wrap">

                        <div className="board-detail-header">
                            <div className="board-detail-title-row">
                                <h4 className="title">{noticeSubject}</h4>
                                <p className="date"><span className="label">등록일</span>{noticerDate}</p>
                            </div>
                        </div>

                        <article className="board-detail-body" dangerouslySetInnerHTML={{ __html: noticeContent  }}>
                        </article>

                        <div className="board-detail-footer">

                            <nav className="board-detail-footer-nav">
                                <a onClick={ ()=> { navigate("/customer/notice/detail/"+ beforeNoticeNumber);  setNoticeNumber(beforeNoticeNumber)}} className="board-detail-footer-nav-link">
                                    <dl>
                                        <dt>이전글</dt>
                                        <dd className="title">{beforeNoticeSubject}</dd>
                                        <dd className="date">{beforeNoticeRDate}</dd>
                                    </dl>
                                </a>
                                <a onClick={ ()=> { navigate("/customer/notice/detail/"+ nextNoticeNumber);  setNoticeNumber(nextNoticeNumber) }} className="board-detail-footer-nav-link">
                                    <dl>
                                        <dt>다음글</dt>
                                        <dd className="title">{nextNoticeSubject}</dd>
                                        <dd className="date">{nextNoticeRDate}</dd>
                                    </dl>
                                </a>
                            </nav>

                        </div>

                        <div className="board-button-row">
                            <div className="row">
                                <div className="col col-12"><a onClick={ ()=> { navigate("/customer/notice")}}
                                                               className="vbtn btn-line-black-black large round btn-board-list">글
                                    목록</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

        </div>
    )
}

export default NoticeDetail;