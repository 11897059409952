import {useEffect} from "react";
import $ from "jquery";
import {gsap} from "gsap";


const SubVisualArticle = (props)=> {
    return (
        <section className="sub-visual-row">
            <article className="sub-visual-article">
                <ul className="breadcrumbs">
                    <li><a href="/Users/ijaeyeong/Desktop/react/test1/src/Pages">HOME</a></li>
                    <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                    <li>회사 소개</li>
                    <li className="icon"><img src="/images/icon-right-triangle.svg" alt=""/></li>
                    <li className="current">{props.name}</li>
                </ul>
                <div className="sub-visual-title">
                    <h2 className="title">
                        <span lang="en">INTRODUCE</span>
                        <span lang="ko">회사 소개</span>
                    </h2>
                </div>
            </article>
            <div className="sub-visual-img">
                <div className="inner">
                    <img src={props.imagePC} alt="" className="pc-only"/>
                    <img src={props.imageMO} alt="" className="mo-only"/>
                </div>
            </div>
        </section>
    )
}


export default SubVisualArticle;