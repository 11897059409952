import TwoImage1 from "../../../images/img-twoapp.jpg";
import TwoImage2 from "../../../images/img-twoapp-mo.jpg";
import FamdaImage1 from "../../../images/img-farmda.jpg";
import FamdaImage2 from "../../../images/img-farmda-mo.jpg";
import ChacadaImage1 from "../../../images/img-chacarda.jpg";
import ChacadaImage2 from "../../../images/img-chacarda-mo.jpg";

const businessIntroData = [
    {
        location: "vehiclecontrol",
        current: "차량관제솔루션",
        subArticleData:[
            {
                id: "chacarda",
                EnName: "CHACARDA",
                KoName1: "차량관제솔루션 차카다",
                content1: "CHACARDA는 차량 위치관제, 온도 모니터링 시스템을 제공하며 etas(한국교통안전공단)의 제출 자료를 제공하는 서비스입니다." ,
                image1: ChacadaImage1,
                image2: ChacadaImage2
            }
        ]

    },
    {
        location: "siIntro",
        current: "SI"
    },
    {
        location: "agriculturalProductsDistribution",
        current: "농산물유통솔루션",
        subArticleData: [
            {
                id: "farmda",
                EnName: "FARMDA",
                KoName1: "농산물 유통 ICT 솔루션",
                KoName2: "FARMDA",
                content1: "농산물 산지 고객 연결, 쇼핑몰과 오픈마켓 및 일반 마켓 통합솔루션 제공, 송장출력 및 CS센터 아웃소싱",
                content2:  "FARMDA에서 생산자의 편의와 최고의 수익을 보장합니다.",
                image1: FamdaImage1,
                image2: FamdaImage2
            }
        ]
    },
    {
        location: "mobileplatform",
        current: "모바일플랫폼",
        subArticleData: [
            {
                id: "mobile",
                EnName: "TWOAPP",
                KoName1: "모바일 플랫폼 TWOAPP",
                content1: "TWOAPP 모바일 엔터프라이즈 애플리케이션 플랫폼",
                content2: "Mobile Enterprise Application Platform",
                image1: TwoImage1,
                image2: TwoImage2
            }
        ]
    },
]

export let businessDataFunction = (location) => {
    for (let i = 0; i <= businessIntroData.length; i++ ){
        if( location == businessIntroData[i].location ) {
            return businessIntroData[i];
        }
    }

}
