
const TopCaseItem = (props)=> {
    return (
        props.topCaseItem.map(function (a,i){
            return(
                <li className="top-case-item wow fadeInUp" key={i}>
                    <dl className="top-case-item-desc">
                        <dt className="top-case-item-title">{props.topCaseItem[i].title}</dt>
                        <dd className="top-case-item-text1">{props.topCaseItem[i].text1}</dd>
                        <dd className="top-case-item-text2">{props.topCaseItem[i].text2}
                        </dd>
                    </dl>
                    <div className="top-case-item-img"><img src={props.topCaseItem[i].image}
                                                            alt=""/></div>
                </li>
            )
        })

    )
}

export default TopCaseItem;



