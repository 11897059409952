
import {Outlet, useLocation} from "react-router-dom";
import {introDataFunction} from "./Data/InfoData";
import {useEffect, useState} from "react";
import $ from "jquery";
import {gsap} from "gsap";


const CompanyInfos =()=> {


    let location = useLocation();
    let locationCheck = location.pathname.split("/")[2].trim();
    let [data , setData] = useState(introDataFunction(locationCheck));

    useEffect(
        ()=> {
            let topImg = $('.sub-visual-img img');
            let tl = gsap.timeline({paused: true});

            tl.to(topImg, {
                'transform-origin': 'center',
                'scale': 1,
                'duration': 2,
                'ease': "expoScale(0.1, 8)"
            }, 'same').to('.sub-visual-title span', {
                opacity: 1,
                y: 0,
                stagger: 0.2,
            }, '-=1.5')
            tl.play();
            // topImgs();
            setData(introDataFunction(locationCheck));
        } ,[location]
    )

    return(
        <div className="subpage-container">
                    <Outlet context={ {data} }></Outlet>
        </div>
    )
}



export default CompanyInfos;