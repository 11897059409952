
const SiAppBoardList = (props)=> {
    return (


            props.siAppBoardListData.map(function (a,i) {
                if(props.siAppBoardListData[i].id != "dongwon") {
                    return (
                        <li className="board-item wow fadeInUp" key={i}>
                            <a href="#" className="board-link">
                                <div className="board-desc">
                                    <dl>
                                        <dt className="board-desc-title">{props.siAppBoardListData[i].title}</dt>
                                        <dd className="board-desc-text">{props.siAppBoardListData[i].descText}</dd>
                                    </dl>
                                </div>
                                <div className="board-img"><img src={props.siAppBoardListData[i].image} alt=""/>
                                </div>
                            </a>
                        </li>
                    )
                } else {
                    return (
                        <li  className="board-item wow fadeInUp"  key={i} style={{ backgroundColor: "#26262B" }} >
                            <a href="#" className="board-link">
                                <div className="board-desc">
                                    <dl>
                                        <dt className="board-desc-title" style={{color:"white"}}>{props.siAppBoardListData[i].title}</dt>
                                        <dd className="board-desc-text" style={{color:"white"}}>{props.siAppBoardListData[i].descText}</dd>
                                    </dl>
                                </div>
                                <div className="board-img"><img src={props.siAppBoardListData[i].image}
                                                                alt=""/>
                                </div>
                            </a>
                        </li>
                    )
                }
            })


    )
}

export default SiAppBoardList;
