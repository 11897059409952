import {useLocation, useNavigate} from "react-router-dom";


const CustomerNavTab = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let locationCheck = location.pathname.split("/")[2].trim();

    return(
        <div className="sub-nav-row" id="customer-nav-tab" data-scroll="">
            <nav className="sub-nav">
                <ul className="sub-nav-list">
                    <li className={"sub-nav-item " + (locationCheck === 'notice' ? 'active': '')} ><a onClick={ ()=> {navigate("/customer/notice")}}> 공지사항</a></li>
                    <li className={"sub-nav-item " + (locationCheck === 'qna' ? 'active': '')} ><a onClick={ ()=> {navigate("/customer/qna")}} >고객문의</a></li>
                </ul>
            </nav>
        </div>
    )
}

export default CustomerNavTab;
