/* global kakao */
import React, { useEffect, useState } from 'react';

const { kakao } = window;

const Maps = () => {
        var mapContainer = document.getElementById('common-contact-map'); // 지도를 표시할 div
        var mapOption = {
            center: new kakao.maps.LatLng(37.357424, 126.953652), // 지도의 중심좌표
            level: 2 // 지도의 확대 레벨
        };

        var map = new kakao.maps.Map(mapContainer, mapOption);
        var geocoder = new kakao.maps.services.Geocoder();

        // 줌 기능 X
        function setZoomable(zoomable) {
            map.setZoomable(zoomable);
        }

        // 주소로 좌표 지정
        geocoder.addressSearch('경기도 군포시 공단로 140번길 38 센트럴비즈파크', function(result, status) {
            if (status === kakao.maps.services.Status.OK) {
                var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
                var position = coords;
                var markerPosition = new kakao.maps.LatLng(37.357331, 126.953520);

                var marker = new kakao.maps.Marker({
                    position: markerPosition
                });

                marker.setMap(map)
                map.setCenter(coords);
                map.relayout();

                setZoomable();
            }
        });
};


/**
//  * 카카오맵 초기화 공통
//  * [메인, contact...]
//  * @param {page name} pageName
//  */
// function commonMapInit(pageName) {
//     var mapContainer = document.getElementById('common-contact-map'); // 지도를 표시할 div
//     var mapOption = {
//         center: new kakao.maps.LatLng(37.3912487228432, 126.971649915351), // 지도의 중심좌표
//         level: 2 // 지도의 확대 레벨
//     };
//
//     var map = new kakao.maps.Map(mapContainer, mapOption);
//     var geocoder = new kakao.maps.services.Geocoder();
//
//     // 줌 기능 X
//     function setZoomable(zoomable) {
//         map.setZoomable(zoomable);
//     }
//
//     // 주소로 좌표 지정
//     geocoder.addressSearch('경기도 안양시 동안구 평촌동 160-1 평촌아이에스비즈타워', function(result, status) {
//         if (status === kakao.maps.services.Status.OK) {
//             var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
//             var position = coords;
//             var markerPosition = new kakao.maps.LatLng(37.3914, 126.9716);
//
//             var marker = new kakao.maps.Marker({
//                 position: markerPosition
//             });
//
//             marker.setMap(map)
//             map.setCenter(coords);
//             map.relayout();
//
//             setZoomable();
//         }
//     });
// }

export default Maps;
